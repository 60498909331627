import React, { Component } from "react";
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import axios from 'axios';
import LoadingBar from "react-top-loading-bar";
import { PropagateLoader } from "react-spinners";

import Swal from 'sweetalert2';


class UploadExcel extends Component {
    constructor(props) {
        super(props);

        this.state = {

            ShowBar:false,
            ShowProcedure:false,
            LoadingBar:false,

            UploadModal: false,
            PublicationExcelFileName:'',
            FinalPublicationExcelFile:'',
            PreliminaryDataErr:'',
            PublicationExcelFile:'',

            Uploadtype:'',
        }





    }

    componentDidMount() {



    }

    
    //PrilimaryData Upload upload resume function
    onUploadPreliminary = async (event) => {
        
        this.setState({
            ShowBar:true,
            ShowProcedure: true,
            LoadingBar:true,
          })

        //const filesFormats= [".xlsx","application/xls"];
        const filesFormats= [".xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
       // alert(event.target.files[0].type);
        console.log(event.target.file);
    
        const isRightFormat=filesFormats.includes(event.target.files[0].type);
        console.log(isRightFormat);
       // alert(event.target.files[0].type);
      if(isRightFormat)
     {
       
        await this.setState({
            
           // ShowPublicationExcel: true,
            PublicationExcelFile: event.target.files[0],
            PublicationExcelFileName: event.target.files[0].name,
            FinalPublicationExcelFile: event.target.files[0].name,
           // PublicationFileIsUploadedRefreshNow: false,
            PreliminaryDataErr:'',

        });
        //console.log("event of resume PreliminaryData", this.state.PublicationExcelFile)
        console.log("event of publication excel", this.state.PublicationExcelFileName)
    }
    else
    {
      this.setState( {
  
        PreliminaryDataErr:"only .xlsx file allowed",
      });
      
    }
  
    this.setState({
        ShowBar:false,
        ShowProcedure: false,
        LoadingBar:false,
      })


    }

    Uploaddata=()=>
    {
      
      let UploadType=sessionStorage.getItem("UploadType");
       
        if(this.state.PublicationExcelFileName && UploadType !=""){

           const urlFile = global.websiteUrl+'/service/Procedure_Speciality/Upload_Procedure_List';            
           let formDataFile = new FormData();
            formDataFile.append('file', this.state.PublicationExcelFile);
            formDataFile.append('oper', UploadType);
            const configfile = {
              headers: { 'content-type': 'multipart/form-data' }
            }

            this.setState({
                ShowBar:true,
                ShowProcedure: true,
                LoadingBar:true,
              })
           
            axios.post(urlFile, formDataFile, configfile)
              .then(response => {


                console.log(response);
                var data = response.data;
                console.log("data publication excel", data);
                var status = response.data.status;
                console.log("status publication", status);   

               if (response.data.status == true) {
                  Swal.fire({
                    icon: 'success',
                    text: 'Upload Success',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  }).then((result)=>{
                    if(result.value)
                    {
                       window.location.reload(true); 
                    }
                  })

                
                  this.setState({
                    ShowPublicationExcel: false,
                    PublicationExcelFile: '',
                    PublicationExcelFileName: '',
                    FinalPublicationExcelFile: '',
                    ShowPublicationExcel: true,
                    ShowBar:false,
                    ShowProcedure: false,
                     LoadingBar:false,
                  })

                }
                else {
                  Swal.fire({
                    icon: 'success',
                    text: 'Upload Success',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  }).then((result)=>{
                    if(result.value)
                    {
                       window.location.reload(true); 
                    }
                  })
                  // Swal.fire({
                  //   icon: 'error',
                  //   text: 'Upload Failed pls check file or sheet name ',
                  //   confirmButtonColor: '#d33',
                  //   confirmButtonText: 'OK'
                  // }).then((result)=>
                  //   {
                  //     if(result.value)
                  //     {
                  //       window.location.reload(true);
                  //     }
        
                  //   })
                }



              })
          }
          else
          {
            Swal.fire({
              icon: 'error',
              text: 'Upload Failed',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            }).then((result)=>
            {
              if(result.value)
              {
                window.location.reload(true);
              }

            })
          }

    }



    render() {

        return (


            <body>

{this.state.ShowBar ? (
          <Modal isOpen={true} centered>
            <PropagateLoader color={"#A90007"} size="30px" loading="true" />
          </Modal>
        ) : null}
        {this.state.progressBar ? (
          <div style={{ position: "fixed", top: "0" }}>
            <LoadingBar
              progress="100"
              height={3}
              color="#A90007"
              transitionTime={4000}
              loaderSpeed={3000}
            />
          </div>
        ) : null}

                {/* <Modal isOpen={true} centered > */}

                <ModalBody style={{ padding: "0px 0px 0px 0px" }}>
                    <FormGroup>
                        <ModalHeader style={{justifyContent:'left'}}><b><h3>Select {sessionStorage.getItem("UploadType")} List</h3></b> </ModalHeader>
                        <div class="form-control" style={{ width: '100%', height: '40px' }}  >
                            <input type='file'
                                accept=".xlsx"
                                id="myInputResume"
                                // accept="image/*"
                                onChange={this.onUploadPreliminary} hidden />
                            <label htmlFor="myInputResume"
                                style={{ color: 'blue', fontSize: "15px", marginTop: '0%' }}>
                                Select file
                            </label>
                            {
                                (this.state.PublicationExcelFileName) ? (
                                    <div class="float-right" style={{ marginTop: '-5%' }}>
                                        <text>{this.state.FinalPublicationExcelFile}</text>
                                    </div>
                                ) : (
                                    <div class="float-right" style={{ marginTop: '-5%' }}>
                                        No file Choosen
                                    </div>
                                )

                            }
                        </div>

                        <div className="loginErrCreateProject" style={{ marginTop: '10px', fontSize: '15px', fontWeight: 'bold' }} >
                            {/* {this.state.PreliminaryDataErr} */}
                            {
                                (this.state.PreliminaryDataErr !== "") ? (
                                    <div>
                                        {(this.state.PreliminaryDataErr == "Uploaded Success") ? (<div style={{ fontSize: '15px', fontWeight: 'bold', color: 'green' }}>
                                            {this.state.PreliminaryDataErr}

                                        </div>) : (<div> {this.state.PreliminaryDataErr}
                                        </div>)
                                        }
                                    </div>) : (<div></div>)

                            }


                        </div>



                        <div style={{ fontSize: 12, color: "red" }}>{this.state.KoltypeEditErr}</div>

                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <button class="cetas-button cetas-button-large center" style={{ height: '40px' }}
                        onClick={this.Uploaddata.bind(this)}>Upload</button>
                    {/* <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                onClick={this.toggleUploadModal.bind(this)}>Cancel</button> */}
                </ModalFooter>
                {/* </Modal> */}
            </body>
        )
    }
}

export default UploadExcel;