
global.CopyRightText="© 2023 Cetas Medtech Gurus. - ISO 9001 Certified"
global.webUrl="https://www.cetas.medtech-gurus.com"

// live data base
//global.websiteUrl="https://www.tech.medtech-gurus.com/Cetas"

// for testing api
//global.websiteUrl="https://app.medtech-gurus.com"


// for devlopment testing api
global.websiteUrl="https://www.cetas.medtech-gurus.com/Cetas"



//global.websiteUrl="http://localhost/Cetas"


