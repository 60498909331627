import React, { Component} from "react";
import { Redirect } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import logo from './Assets/Images/1.png';

import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
//import Spinner from '../Assets/Spinner'
import {
  PropagateLoader,
} from "react-spinners";



var jwt = require('jsonwebtoken');
const jwtkey=require('./config');

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

class home extends Component {
  // typeAheadref = useRef < Typeahead > (null);
  
  constructor(props) {
    super(props);
    this.state = {
      subClinicalKolEnabled: true,
      subSpecilityVisible: false,
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectLogin: false,
      redirectSignup: false,
      redirecthome: false,
      redirectPClientViewKolList: false,
      redirectAdministrator: false,
      profiletypeErr: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,

      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      redirectServices: false,

      kdidentificationhidediv: false,
      kdprofilinghidediv: false,
      kdinteracthidediv: false,

      GetInTouchModal: true,

      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: "",
      pretoken:'',
      resercher_name: "",
      kols: [
        { id: "clinical", label: "Clinical" },
        { id: "non-clinical", label: "Non-Clinical" },
      ],
      kolSubType: [
        { value: "Payers", label: "Payers" },
        { value: "HTA Experts", label: "HTA Experts" },
        {
          value: "Hospital Procurement Experts",
          label: "Hospital Procurement Experts",
        },
        { value: "Advocacy Group Experts", label: "Advocacy Group Experts" },
        {
          value: "MedTech Industry Experts",
          label: "MedTech Industry Experts",
        },
      ],
      GetInTouchData: {
        client_name: "",
        email_id: "",
        company_nm: "",
        mobile_no: "",
        designation: "",
        message: "",
        status: "New",
        // loginType: 'pre_client',
      },
      Procedure: "",
      procedureSelected: false,
      conditionSelected: false,
      // Manager info
      manager_id: "",
      manager_name: "",

      // Employee info
      employee_id: "",
      employee_name: "",

      speciality_id: "",
      speciality_name: "",

      // For validations
      PhoneErr: "",
      DesignationErr: "",
      MessageErr: "",
      UsernameErr: "",
      UseremailErr: "",
      CompanyNameErr: "",

      PasswordErr: "",
      SelectRoleErr: "",
      wrngUsPsErr: "",

      clientUsernameErr: "",
      clientPasswordErr: "",

      administratorUsernameErr: "",
      administratorPasswordErr: "",

      visitorUsernameErr: "",
      visitorPasswordErr: "",

      kolProfileUsernameErr: "",
      kolProfilePasswordErr: "",
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      loginData: {
        email_id: 'guest@cetas-healthcare.com',
        password: 'vdo1Xrjn',
        login_type:'pre_client'      
      },


      
      contact_us: {
        first_name: "",
        last_name: "",
        organization: "",
        email_id: "",
        phone_no: "",
        country: "",
        learn_qpharma: "",
        representative_contact_me: "",
        qpharmas_products: "",
        technical_support_issue: "",
        need_of_assistance: "",
        receive_qpharma_news: "",
        describe_issue_question: "",
      },
      cuFirstNameErr: "",
      cuLastNameErr: "",
      cuOrganizaErr: "",
      cuEmailErr: "",
      cuPhoneErr: "",
      cuCountryErr: "",

      new_guru: {
        first_name: "",
        last_name: "",
        mobile_no: "",
        email_id: "",
        speciality: "",
        sub_speciality: "",
        procedure_type: "",
        country: "",
        state: "",
        current_place_of_work: "",
        department: "",
        linkedin_link: "",
        additional_info: "",
      },
      ngFirstNameErr: "",
      ngLastNameErr: "",
      ngEmailErr: "",
      cuPhoneErr: "",
      ngCountryErr: "",
      ngStateErr: "",

      new_client: {
        first_name: "",
        last_name: "",
        email_id: "",
        mobile_no: "",
        msg: "",
      },
      ncFirstNameErr: "",
      ncLastNameErr: "",
      ncEmailErr: "",
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: "",
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: "",
        role: "",
      },
      selectedKol: "",
      searchModalOpen: false,
      kolFormSubmitted: false,
      countryList: [
        { value: "USA", label: "USA" },
        { value: "Canada", label: "Canada" },
        { value: "UK", label: "UK" },
        { value: "Germany", label: "Germany" },
        { value: "France", label: "France" },
        { value: "Italy", label: "Italy" },
        { value: "Spain", label: "Spain" },
      ],
      clinicalKolForm: {
        speciality: "",
        subSpeciality: "",
        procedure: "",
        country: "",
      },
      nonClinicalKolForm: {
        kolSubType: "",
        country: "",
        fullName: "",
        workEmail: "",
        phone: "",
        companyName: "",
        jobTitle: "",
        message: "",
      },
      nonClinicalContactFormSubmitted: false,
      showNonClinicalContactForm: false,
    };

  

  }

 


  componentDidMount = async () => {
    console.log("in componentDidMount");

    jwt.sign(this.state.loginData,jwtkey.jwt.key,{expiresIn: jwtkey.jwt.expiresIn},(err,result)=>
      {
        if(!err)
        {
          //console.log('wwwtoken',result);
          sessionStorage.setItem('wtoken',result); 
          
          sessionStorage.removeItem("speciality_id");  
          sessionStorage.setItem("id", "Guest");
        
        }
      })

      this.generate_pretoken();
   };


   




   generate_pretoken()
   {
    const options = {
      method: "POST",
    };

    // Get Token
    fetch(
      global.websiteUrl +
      "/service/client/get_token",
      options
    )
      .then((Response) => Response.json())

      //     const url =
      //     global.websiteUrl +
      //     "/service/client/get_token";
      //   let formData = new FormData();
      //   formData.append('key',jwtkey.jwt.key);       
      //   const config = {
      //     //   headers: { 'content-type': 'multipart/form-data' }
      //   };
      //   axios
      // .post(url, formData, config)
      // // .then((Response) => Response.json())

      .then((findresponse) => {
        var testDataSpeciality = findresponse.tokeninfo;
        console.log("testDataSpeciality", testDataSpeciality);
        if (findresponse.tokeninfo != "undefined") {
        
          this.setState({
              pretoken:findresponse.tokeninfo
          });

          sessionStorage.setItem('token', findresponse.tokeninfo);        
          const url =
                global.websiteUrl +
                "/service/KOL_landing/Get_Speciality_List";
              let formData = new FormData();
              formData.append('token_id',findresponse.tokeninfo);
              const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
              };
              axios
            .post(url, formData, config)
            .then((response) => {
              console.log(response);
              const SpecialityData = response.data;
              console.log("SpecialityData", JSON.stringify(SpecialityData));
              if (SpecialityData.status === true) {
                this.setState({
                  SpecialityData: response.data.data.map((e) => ({
                    label: e.speciality_type,
                    value: e.id,
                  }))
          
                });
                
              }
            })
            .catch((error) => {
              console.error('There was an error!', error);
            });
    

            
    //get country list 
      const url1 =
      global.websiteUrl +
      "/service/Location/get_CountryList";
    // let formData1 = new FormData();
    // formData1.append('token_id',getSession_Client_token);
    // const config1 = {
    //   //   headers: { 'content-type': 'multipart/form-data' }
    // };
    axios
  .post(url1, formData, config)
  .then((findresponse) => {
  // console.log("country=",findresponse);
    const CountryData = findresponse.data;
    if (CountryData.status === true) {
      this.setState({
        countryList: findresponse.data.Country_Details.map(c => ({ "value": c.id, "label": c.country_name })),
        Client_Unique_id: findresponse.data.client_system_id,

      });
    // console.log("CountryData", this.state.countryList);
      
    }
  })
  .catch((error) => {
    console.error('There was an error!', error);
  });




        }
      });


   }


  setSubSpecility = (id) => {
    const formdata = new FormData();
    formdata.append("speciality_type_id", id);
    formdata.append('token_id',this.state.pretoken);
             
    const options = {
      method: "POST",
      body: formdata,
    };
    // Speciality type data
    const url = "/service/KOL_landing/Get_Sub_Speciality_List";
    fetch(global.websiteUrl + url, options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        let subSpecilityList = findresponse.data.map(e => ({
          label: e.sub_speciality_type,
          value: e.id,
        }))
        if (findresponse.data != "undefined") {
          this.setState({
            SubSpecialityType: subSpecilityList,
            subSpecilityVisible: subSpecilityList[0].label !== "NA" ? true : false
          });
        }
      });
  };

  toggleGetInTouchModal() {
    //console.log("loginclicked")

    this.setState({
      GetInTouchModal: !this.state.GetInTouchModal,
    });
  }

  //login = toggle validate and login
  toggleLoginModal() {
    this.setState({
      redirectLogin: true,
    });
  }

  toggleSignUpModal() {
    sessionStorage.removeItem("quotation_id", "");
    this.setState({
      redirectSignup: true,
    });
  }

  toggleWithoutidModal() {
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }
  closeSearchModal() {
    this.setState({ searchModalOpen: false, kolFormSubmitted: false });
  }
  closeNonClinicalContactModal() {
    this.setState({ showNonClinicalContactForm: false });
  }
  openSearchModal() {
    if (this.state.selectedKol != "") {
      console.log(this.state.selectedKol);
      this.setState({ searchModalOpen: true });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select KOL",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }

  makespaceondivkdidentification() {
    this.setState({
      hidedivkdprofiling: false,
      hidedivkdinteract: false,
      hidedivkdidentification: true,
    });
  }

  makespaceondivkdprofiling() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdinteract: false,
      hidedivkdprofiling: true,
    });
  }

  makespaceondivkdinteract() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: true,
    });
  }

  makespaceondivhome() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: false,
    });
  }

  validate = () => {
    let UsernameErr = "";
    let UseremailErr = "";
    let CompanyNameErr = "";
    let PhoneErr = "";
    let DesignationErr = "";
    let MessageErr = "";

    if (!this.state.GetInTouchData.client_name) {
      UsernameErr = "Please Enter Full Name";
    }
    if (!this.state.GetInTouchData.email_id) {
      UseremailErr = "Please Enter Email id";
    }

    if (!this.state.GetInTouchData.company_nm) {
      CompanyNameErr = "Please Enter Company Name";
    }

    if (!this.state.GetInTouchData.mobile_no) {
      PhoneErr = "Please Enter phone";
    }

    if (!this.state.GetInTouchData.designation) {
      DesignationErr = "Please Enter designation";
    }

    if (!this.state.GetInTouchData.message) {
      MessageErr = "Please Enter message";
    }

    if (
      UsernameErr ||
      UseremailErr ||
      CompanyNameErr ||
      PhoneErr ||
      DesignationErr ||
      MessageErr
    ) {
      this.setState({
        UsernameErr,
        PhoneErr,
        UseremailErr,
        CompanyNameErr,
        DesignationErr,
        MessageErr,
      });
      return false;
    }
    return true;
  };

  save_getintouch() {
    const isValid = this.validate();

    if (isValid) {
      this.setState({ ShowBar: true });

      const url =
        global.websiteUrl + "/service/KOL_landing/check_duplicate_get_in_touch";
      let formData = new FormData();
      formData.append("email_id", this.state.GetInTouchData.email_id);
      formData.append('token_id',this.state.pretoken);
   

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          var lgstatus = response.data.status;
          // alert(lgstatus);
          // console.log("lgstatus", JSON.stringify(lgstatus));

          this.setState({ ShowBar: false });
           if (lgstatus == false || lgstatus ==="undefined" ) {
            this.SaveGetInTouchDatafun();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: "Emails Already exits pls change new email",
              confirmButtonColor: "#d33",
              confirmButtonText: "OK",
            });

            this.setState({
              UseremailErr: "Change New Email",
              confirmpasswordErr: "",
              UsernameErr: "",
              CompanyNameErr: "",
              PasswordErr: "",
              termconditionErr: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  SaveGetInTouchDatafun() {
    this.setState({
      ShowBar: true,
    });

    const url =
    global.websiteUrl + "/service/KOL_landing/save_enqury";
    let formData = new FormData();

    formData.append(
      "enqury_info",
      JSON.stringify(this.state.GetInTouchData),      
    );
   formData.append('token_id',this.state.pretoken)
   
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        var lgdata = response.data;
        console.log("lgdata", JSON.stringify(lgdata));
        var lgstatus = response.data.status;
        console.log("lgstatus", JSON.stringify(lgstatus));
        //      console.log("response message2", response.data.message)

        this.setState({
          ShowBar: false,
        });

        if (response.data.status == true) {
          Swal.fire({
            icon: "success",
            //text: response.data.message,
            text: "Success! Your message has been successfully delivered to us. Our team will get in touch with one working day.",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }

        setTimeout(function () {
          window.location.reload();
        }, 4000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  submitKOLform() {
    this.setState({ kolFormSubmitted: true });
    if (this.state.selectedKol === this.state.kols[0].label) {
      if (this.state.clinicalKolForm.speciality) {
        sessionStorage.setItem("email", "");
        sessionStorage.setItem("id", "Guest");
        sessionStorage.setItem("name", "");
        sessionStorage.setItem(
          "subspeciality",
          this.state.clinicalKolForm.subSpeciality?.[0]?.label
        );
        sessionStorage.setItem(
          "procedure",
          this.state.clinicalKolForm.procedure?.[0]?.label
        );
        sessionStorage.setItem(
          "speciality_id",
          this.state.clinicalKolForm?.speciality?.[0]?.value
        );
        sessionStorage.setItem(
          "speciality",
          this.state.clinicalKolForm?.speciality?.[0]?.label
        );
        this.setState({ redirectPClientViewKolList: true });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please Select Speciality",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } else {
      if (this.state.nonClinicalKolForm.kolSubType) {
        console.log(this.state.nonClinicalKolForm.kolSubType)
        this.setState({
          showNonClinicalContactForm: true,
          searchModalOpen: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please Select KOL Subtype",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  }

  handleClinicalFormField(id, value) {
    console.log({
      id,
      value,
    });
    let { clinicalKolForm } = this.state;
    // this.setState({
    //   ProcedureType: []
    // })
    if (id != '')
      clinicalKolForm[id] = value;

    clinicalKolForm['procedureType'] = this.state.Procedure
    clinicalKolForm['procedure'] = ""

    // const spId = this.state.SpecialityData.filter(sp => sp.speciality_type === value)[0]
    if (id == "speciality" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
      this.setSubSpecility(value?.[0]?.value);
      this.state.profiletypeErr = false;

      // this.refs.typeahead.getInstance().clear()
      this.setState({
        ProcedureType: [],
        Procedure: false,
        procedureSelected: false,
        conditionSelected: false
      })
    } else if (id == "procedure" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
    } else {
      this.state.subClinicalKolEnabled = true
    }
    this.setState({ clinicalKolForm: clinicalKolForm });
    console.log(this.state.clinicalKolForm);
  }
  handleNonClinicalFormField(id, value) {
    this.state.subClinicalKolEnabled = false
    let { nonClinicalKolForm } = this.state;
    nonClinicalKolForm[id] = value;
    this.setState({ nonClinicalKolForm: nonClinicalKolForm });
  }
  validateField(type, id) {
    console.log('Validate field called with - ', { type, id })
    const form = this.state[type];
    if (this.state.kolFormSubmitted) {
      if (form[id]) {
        console.log('returning false')
        return false;
      } else {
        console.log('returning true')
        return true;
      }
    } else {
      console.log('returning false')
      return false;
    }
  }
  validateContactFormField(id) {
    const form = this.state.nonClinicalKolForm;
    // console.log(this.state.nonClinicalContactFormSubmitted, "check");
    if (this.state.nonClinicalContactFormSubmitted) {
      if (form[id]) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  onProcedureChanged = (e) => {
    console.log(e.target.value);
    let { clinicalKolForm } = this.state
    console.log(clinicalKolForm)
    let speciality_id = clinicalKolForm.speciality
    if (!speciality_id) {
      this.state.profiletypeErr = 'Please select Speciality from dropdown!'
    }
    const value = e.target.value;
    this.setState({
      ProcedureType: [],
    });
    // console.log(this.refs.typeahead.clear())
    if (this.refs.typeahead) { this.refs.typeahead.clear() }
    value === "Procedure" ? this.setState({ procedureSelected: true, conditionSelected: false }) : this.setState({ conditionSelected: true, procedureSelected: false })
    const formdata = new FormData();
    if (speciality_id?.[0]) {
      formdata.append("speciality_type_id", speciality_id[0].value);
      formdata.append('token_id',this.state.pretoken);
   

      this.setState({ Procedure: value });
      this.state.profiletypeErr = false

    }
    const options = {
      method: "POST",
      body: formdata,
    };
    let url =
      value === "Procedure"
        ? "/service/KOL_landing/Get_Procedure_List"
        : "/service/KOL_landing/Get_Condition_List";
    fetch(global.websiteUrl + url, options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        var procedureList = findresponse.data;
        if (procedureList != "undefined") {
          this.setState({
            ProcedureType: procedureList.map((item) => ({
              label:
                value === "Procedure"
                  ? item.procedure_type
                  : item.condition_type,
              value: item.id,
            })),
          });
        }
      });
  };
  render() {

    
    

    if (this.state.redirect) {
      return <Redirect to={"/home"} />;
    }

    if (this.state.redirecthome) {
      return <Redirect to={"/home"} />;
    }

    if (this.state.redirectLogin) {
      return <Redirect to={"/user login"} />;
    }

    if (this.state.redirectSignup) {
      return <Redirect to={"/create new account"} />;
    }

    if (this.state.redirectPClientViewKolList) {
      return <Redirect to={"/KOL Directory"} />;
    }

    if (this.state.redirectAdmin) {
      return <Redirect to={"/AdminDashboard"} />;
    }

    if (this.state.redirectResercher) {
      return (
        <Redirect
          to={{
            pathname: "/ResercherDashboard",
            state: {
              RID: this.state.RID,
              resercher_name: this.state.resercher_name,
            },
          }}
        />
      );
    }

    if (this.state.redirectManager) {
      return (
        <Redirect
          to={{
            pathname: "/ManagerDashboard",
            // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name}
          }}
        />
      );
    }

    if (this.state.redirectEmployee) {
      return (
        <Redirect
          to={{
            pathname: "/EmployeeDashboard",
            //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name}
          }}
        />
      );
    }

    if (this.state.redirectClientDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/ClientViewProject",
          }}
        />
      );
    }

    if (this.state.redirectAdministratorDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/AdministratorViewProject",
          }}
        />
      );
    }

    if (this.state.redirectVisitorDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/VisitorDashboard",
          }}
        />
      );
    }

    if (this.state.redirectKolProfile) {
      return (
        <Redirect
          to={{
            pathname: "/KolProfileDashboard",
          }}
        />
      );
    }

    if (this.state.redirectTermsandPrivacy) {
      return (
        <Redirect
          to={{
            pathname: "/TermsPrivacyPolicy",
          }}
        />
      );
    }
    if (this.state.redirectVisitorDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/VisitorDashboard",
          }}
        />
      );
    }
    if (this.state.redirectKolProfile) {
      return (
        <Redirect
          to={{
            pathname: "/KolProfileDashboard",
          }}
        />
      );
    }
    if (this.state.redirectabout) {
      return (
        <Redirect
          to={{
            pathname: "/about us",
          }}
        />
      );
    }
    if (this.state.redirectpricing) {
      return (
        <Redirect
          to={{
            pathname: "/pricing",
          }}
        />
      );
    }
    if (this.state.redirectContact) {
      return (
        <Redirect
          to={{
            pathname: "/contact us",
          }}
        />
      );
    }
    if (this.state.redirectBlogs) {
      return (
        <Redirect
          to={{
            pathname: "/blogs",
          }}
        />
      );
    }
    if (this.state.redirectCaseStudy) {
      return (
        <Redirect
          to={{
            pathname: "/casestudy",
          }}
        />
      );
    }
    if (this.state.redirectKOLCompliant) {
      return (
        <Redirect
          to={{
            pathname: "/compliant",
          }}
        />
      );
    }
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) => (
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}
      </option>
    ));

    return (
      <body id="landing-body">
        <div>
          {this.state.ShowBar ? (
            <Modal isOpen={true} className="propagateLoader" centered>
              <PropagateLoader color={"#A90007"} size="40px" loading="true" />
            </Modal>
          ) : null}
        </div>
        {this.state.ShowProcedure ? (
          <Modal isOpen={true} className="propagateLoader" centered>
            <PropagateLoader color={"#A90007"} size="40px" loading="true" />
          </Modal>
        ) : null}

        {AddLibrary("./js/app.js")}

        <nav
          className="navbar navbar-expand-lg navbar-landing sticky-top" id="navbar"
        >
          <div className="content container-fluid">
            <a
              className="navbar-brand"
              href="https://www.cetas.medtech-gurus.com"
            >
           
             {/* <img src={require('../src/Assets/Images/Medtech_Gurus_logo.png')}></img> */}
             <img src={require('../src/Assets/Images/Medtech_Gurus_logo.png')}></img>
           
            </a>
            <div className="menu-bar">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bx bx-menu"></i>
              </button>
              <div
                className="navbar-collapse collapse"
                id="navbarNav">
                <ul className="navbar-nav me-md-auto ms-auto mt-2 mt-lg-0">
                  <li className="nav-item">
                    <a
                      href='/home' onClick={this.redirecthome}
                      className="nav-link"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/about us' onClick={this.redirectabout}
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/kol identification' onClick={this.redirectKOLidentification}
                    >
                      KOL Identification
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/kol engagement' onClick={this.redirectKOLEngagement}
                    >
                      KOL Engagement
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/pricing' onClick={this.redirectpricing}
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/blogs' onClick={this.redirectBlogs}
                    >
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/contact us' onClick={this.redirectContact}
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-signup align-items-center d-flex d-lg-none">
                    <a
                      href="javascript:void(0);"
                      style={{ color: "#A90007", fontWeight: "bolder" }}
                      data-bs-toggle="modal"
                      onClick={this.toggleLoginModal.bind(this)}
                    >
                      Log In
                    </a>
                    <a
                      href="javascript:void(0);"
                      style={{ color: "#A90007", fontWeight: "bolder" }}
                      data-bs-toggle="modal"
                      onClick={this.toggleSignUpModal.bind(this)}
                    >
                      / Sign Up
                    </a>
                  </li>
                </ul>

              </div>
            </div>
            <div className="Navheaderlink justify-content-between d-none d-lg-flex">
              <ul className="navbar-nav  align-items-center gap-20">
                <li className="nav-social">
                  <a
                    href="https://www.linkedin.com/company/cetas-healthcare/"
                    target="_blank"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </li>
                <li className="nav-item-email">
                  <a
                    className="link-h email-link"
                    onClick={this.makespaceondivhome.bind(this)}
                    href="mailto:kolmanagement@cetas-healthcare.com"
                    style={{ fontWeight: "normal" }}
                  >
                    <img src="./static/media/email.f2b82b84.svg" />
                    kolmanagement@cetas-healthcare.com
                  </a>
                </li>
              </ul>

              <div className="nav-signup align-items-center">
                <a
                  href="javascript:void(0);"
                  style={{ color: "#A90007", fontWeight: "bolder" }}
                  data-bs-toggle="modal"
                  onClick={this.toggleLoginModal.bind(this)}
                >
                  Log In
                </a>
                <a
                  href="javascript:void(0);"
                  style={{ color: "#A90007", fontWeight: "bolder" }}
                  data-bs-toggle="modal"
                  onClick={this.toggleSignUpModal.bind(this)}
                >
                  / Sign Up
                </a>
              </div>
            </div>
          </div>
        </nav>

{/* <div><h1> Status is<checkonlinestatus/></h1></div> */}

        <div id="search" className="text-center">
          <div class="searchbanner">
            {/* Search Box
             */}
            <div className="searchbox">
              <Input
                type="select"
                id="SpecilitySelect"
                style={{ backgroundColor: "white" }}
                value={this.state.selectedKol}
                onChange={(e) => {
                  console.log(e.target.value, "target");
                  this.setState({ selectedKol: e.target.value});
                }}
              >
                <option value="">Search KOLs by specific procedure or disease condition</option>
                {this.state.kols.map((kol) => (
                  <option key={kol.id} value={kol.label}>
                    {kol.label}
                  </option>
                ))}
              </Input>
            </div>
            {/* Search Button */}
            <div className="searchbutton">
              <a
                href="javascript:void(0);"
                data-bs-toggle="modal"
                onClick={() => this.openSearchModal()}
                className="btn btn-brand ms-lg-1"
                style={{
                  paddingLeft: "20px",
                  marginTop: "-5px",
                  paddingRight: "30px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "12px",
                }}
              >
                {" "}
                <i
                  class="fa fa-search"
                  style={{ fontWeight: "lighter" }}
                ></i>{" "}
                &nbsp;&nbsp; SEARCH
              </a>
            </div>
            {/* Talk to expert */}
            <div className="talktoexpert">
              <a
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#contactform"
                className="btn btn-brand ms-lg-3"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginTop: "-5px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "12px",
                }}
              >
                Demo
              </a>
            </div>
          </div>
        </div>

        <div id="banner" className="text-center">
          <div class="banner"></div>
        </div>
        <section class="section">
          <div class="container">
            <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#kol1" role="tab">
                  KOL Identification
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#kol2" role="tab">
                  KOL Engagement
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#compliant" role="tab">
                Compliance
                </a>
              </li>
            </ul>

            <div class="tab-content">
              <div class="tab-pane active" id="kol1" role="tabpanel">
                <div class="row align-items-center gy-4">
                  <div class="col-lg-6 order-2 order-lg-1">
                    <div class="text-dark">
                      <h5 class="fs-14 text-uppercase text-brand">Key Opinion Leader Network</h5>
                      <h3 class="mb-3 fw-bold">Identification</h3>
                      <p class="mb-4 fs-16">Assists organizations in identifying and mapping key opinion leaders (KOLs) who are influential in their specific medical fields. This service leverages data-driven analytics to recognize experts who can significantly impact product adoption and market penetration.</p>
                      <div class="mt-4">
                        <a href="/kol identification" class="btn btn-primary">Learn More <i class="ri-arrow-right-line align-middle ms-1"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-7 col-12 ms-md-auto order-1 order-lg-2">
                    <div>
                      <img src={require('../src/Assets/Images/identification.jpg')} alt="" class="img-fluid"></img>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="kol2" role="tabpanel">
                <div class="row align-items-center gy-4">
                  <div class="col-lg-6 order-2 order-lg-1">
                    <div class="text-dark">
                      <h5 class="fs-14 text-uppercase text-brand">Key Opinion Leader Network</h5>
                      <h3 class="mb-3 fw-bold">Engagement</h3>
                      <p class="mb-4 fs-16">Offers strategies and tools for engaging with identified KOLs effectively. This includes planning and executing communication strategies that ensure meaningful and impactful interactions with these key stakeholders in the medical community.</p>
                      <div class="mt-4">
                        <a href="/kol engagement" class="btn btn-primary">Learn More <i class="ri-arrow-right-line align-middle ms-1"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-7 col-12 ms-md-auto order-1 order-lg-2">
                    <div>
                      <img src={require('../src/Assets/Images/engagement.jpg')} alt="" class="img-fluid"></img>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="compliant" role="tabpanel">
                <div class="row align-items-center gy-4">
                  <div class="col-lg-6 order-2 order-lg-1">
                    <div class="text-dark"> 
                      <h5 class="fs-14 text-uppercase text-brand">Key Opinion Leader Network</h5>
                      <h3 class="mb-3 fw-bold">Compliance</h3>
                      <p class="mb-4 fs-16">We ensure that our operations meet key international standards. We aim to maintain high-quality standards and client trust through strict adherence to regulatory and statutory requirements.</p>
                      <div class="mt-4">
                        <a href="/compliant" class="btn btn-primary">Learn More <i class="ri-arrow-right-line align-middle ms-1"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-7 col-12 ms-md-auto order-1 order-lg-2">
                    <div>
                      <img src={require('../src/Assets/Images/compliant.jpg')} alt="" class="img-fluid"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="search" className="text-center">
          <p className="service_insights m-0">
            Your One-Stop-Solution for Key Opinion Leaders identification and
            engagement
          </p>
        </div>
        <footer class="footer m-0 bg-black p-2 footer-bottom">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 col-md-6">
                <p class="mb-0 fs-12 text-white">{global.CopyRightText}</p>
              </div>
              <div class="col-12 col-md-6">
                <div class="resources">
                  <div class="d-block d-md-flex justify-content-end align-items-center">
                    <a href="https://www.cetas.medtech-gurus.comprivacy_policy/" class="fs-12 text-decoration-none me-2">Privacy Policy</a>
                    <a href="https://www.cetas.medtech-gurus.comterms-of-use/" class="fs-12 text-decoration-none mx-1">Terms & Conditions</a>
                    <a href="https://www.cetas.medtech-gurus.comcookie-notice/" class="fs-12 text-decoration-none ms-2">Security</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div class="modal fade" id="contactform" tabindex="-1" aria-labelledby="contactformLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="contactformLabel">Get in touch with us</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h5 className="mb-2">Please fill in the form below and we will get in touch within 1 working day</h5>
                <form className="row align-items-center justify-content-between g-2">
                  <div className="col-md-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Full Name:<span class="mandatory">*</span></label> <div className="error"> {this.state.UsernameErr}</div></div>
                    <input type="text" className="form-control mb-0" placeholder="" id="" aria-describedby="" value={this.state.GetInTouchData.client_name} onChange={(e) => {
                      let { GetInTouchData } = this.state;
                      GetInTouchData.client_name = e.target.value;
                      this.setState({ GetInTouchData });
                    }}
                    ></input>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Your work email:<span class="mandatory">*</span></label> <div className="error"> {" "} {this.state.UseremailErr}</div></div>
                    <input type="text" className="form-control mb-0" placeholder="" id="" aria-describedby="" value={this.state.GetInTouchData.email_id}
                      onChange={(e) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.email_id = e.target.value;
                        this.setState({ GetInTouchData });
                      }}
                    ></input>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Your work phone:<span class="mandatory">*</span></label> <div className="error"> {this.state.PhoneErr}</div></div>
                    <PhoneInput
                      inputStyle={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                      width="50%"
                      country={"in"}
                      value={this.state.GetInTouchData.mobile_no}
                      onChange={(mobile_no) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.mobile_no = mobile_no;
                        this.setState({ GetInTouchData });
                      }}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Your company name:<span class="mandatory">*</span></label><div className="error">{" "}{this.state.CompanyNameErr}</div></div>
                    <input
                      type="text"
                      className="form-control mb-0"
                      placeholder=""
                      id=""
                      aria-describedby=""
                      value={this.state.GetInTouchData.company_nm}
                      onChange={(e) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.company_nm = e.target.value;
                        this.setState({ GetInTouchData });
                      }}
                    ></input>
                  </div>
                  <div className="col-12">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Your job title:<span class="mandatory">*</span></label> <div className="error"> {" "} {this.state.DesignationErr} </div></div>
                    <input
                      type="text"
                      className="form-control mb-0"
                      placeholder=""
                      id=""
                      aria-describedby=""
                      value={this.state.GetInTouchData.designation}
                      onChange={(e) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.designation = e.target.value;
                        this.setState({ GetInTouchData });
                      }}
                    ></input>
                  </div>
                  <div className="col-12">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Message:<span class="mandatory">*</span></label><div className="error"> {this.state.MessageErr}</div></div>
                    <textarea
                      name=""
                      placeholder=""
                      className="form-control mb-0"
                      id=""
                      rows="3"
                      value={this.state.GetInTouchData.message}
                      onChange={(e) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.message = e.target.value;
                        this.setState({ GetInTouchData });
                      }}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <a
                  onClick={this.save_getintouch.bind(this)}
                  className="btn btn-brand"
                >
                  Submit
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.searchModalOpen}
          toggle={() => this.closeSearchModal()}
          centered
          fullscreen
        >
          <ModalHeader toggle={() => this.closeSearchModal()}>
            <h3>{this.state.selectedKol} KOL</h3>
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px" }}>
            <div style={{ margin: "20px 0px" }}>
              {this.state.selectedKol === this.state.kols[0].label ? (
                <Form>
                  <FormGroup>
                    <Label>Select Country</Label>
                    <Typeahead
                      id="clinical_country"
                      multiple
                      onChange={(e) =>
                        this.handleClinicalFormField("country", e)
                      }
                      options={this.state.countryList}
                      placeholder="Choose a Country..."
                      selected={this.state.clinicalKolForm.country}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Select Speciality <span class="mandatory">*</span>
                    </Label>
                    <Typeahead
                      id="speciality"
                      invalid={this.validateField(
                        "clinicalKolForm",
                        "speciality"
                      )}
                      onChange={(e) =>
                        this.handleClinicalFormField("speciality", e)
                      }
                      options={this.state.SpecialityData}
                      placeholder="Choose a Speciality..."
                      selected={this.state.clinicalKolForm.speciality}
                    />
                    {this.validateField("clinicalKolForm", "speciality") && (
                      <FormFeedback>Please Select Speciality</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup style={this.state.subSpecilityVisible ? {} : { "display": "none" }}>
                    <Label>Select Sub-Speciality</Label>
                    <Typeahead
                      id="subSpeciality"
                      multiple
                      onChange={(e) =>
                        this.handleClinicalFormField("subSpeciality", e)
                      }
                      options={this.state.SubSpecialityType}
                      placeholder="Choose a Sub-speciality..."
                      selected={this.state.clinicalKolForm.subSpeciality}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Please specify the procedure/condition (if any)</Label>
                    <div
                      onChange={this.onProcedureChanged}
                      style={{
                        height: "30px",
                        width: "100%",
                        // color: "#949393",
                        backgroundColor: "#fff",
                        borderColor: "#ccc",
                      }}
                    >
                      <input
                        type="radio"
                        value="Procedure"
                        name="Procedure"
                        checked={this.state.procedureSelected}
                      />
                      <span className="Radio_button">Procedure </span>
                      <input
                        type="radio"
                        value="Condition"
                        name="Procedure"
                        checked={this.state.conditionSelected}
                        style={{ marginLeft: "5%" }}
                      />{" "}
                      Condition
                    </div>

                    <div className="Quotblanck">
                      {this.state.profiletypeErr ? this.state.profiletypeErr : null}
                    </div>
                  </FormGroup>
                  {this.state.Procedure && (
                    <FormGroup>
                      <Label>Select {this.state.Procedure}</Label>
                      <Typeahead
                        ref='typeahead'
                        id="procedure"
                        onChange={(e) =>
                          this.handleClinicalFormField("procedure", e)
                        }
                        options={this.state.ProcedureType}
                        placeholder={`Choose a ${this.state.Procedure}...`}
                        selected={this.state.clinicalKolForm.procedure}
                      />
                    </FormGroup>
                  )}


                </Form>
              ) : (
                <Form>
                  <FormGroup>
                    <Label>
                      Select KOL Subtype <span class="mandatory">*</span>
                    </Label>
                    <Typeahead
                      id="kolSubType"
                      onChange={(e) =>
                        this.handleNonClinicalFormField("kolSubType", e)
                      }
                      options={this.state.kolSubType}
                      placeholder="Choose a KOL Subtype..."
                      selected={this.state.nonClinicalKolForm.kolSubType}
                    />

                    {this.validateField("nonClinicalKolForm", "kolSubType") && (
                      <FormFeedback>Please Select Sub-KOL Type</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Select Country</Label>
                    <Typeahead
                      id="nonclinical_country"
                      multiple
                      onChange={(e) =>
                        this.handleNonClinicalFormField("country", e)
                      }
                      options={this.state.countryList}
                      placeholder="Choose a Country..."
                      selected={this.state.nonClinicalKolForm.country}
                    />
                  </FormGroup>
                </Form>
              )}
            </div>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.submitKOLform()}
              className="btn btn-brand ms-lg-3"
              disabled={this.state.subClinicalKolEnabled}
            >
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={() => this.closeSearchModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.showNonClinicalContactForm}
          toggle={() => this.closeNonClinicalContactModal()}
          size="lg"
          style={{ maxWidth: "700px", width: "100%" }}
          centered
          fullscreen
        >
          <ModalHeader toggle={() => this.closeNonClinicalContactModal()}>
            <h1>Get in touch with us</h1>
            <p>
              Please fill in the form below and we will get in touch within 1
              working day
            </p>
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px" }}>
            <form className="p-lg-5 col-12 row g-3">
              <div className="col-lg-6">
                <label for="" className="form-label">
                  KOL Subtype:<span class="mandatory">*</span>
                </label>
                <Input
                  type="select"
                  id="SpecilitySelect"
                  value={this.state.nonClinicalKolForm.kolSubType[0]?.label}
                  onChange={(e) =>
                    this.handleNonClinicalFormField(
                      "kolSubType",
                      e.target.value
                    )
                  }
                  style={{ backgroundColor: "white" }}
                >
                  <option>Select KOL Subtype</option>
                  {this.state.kolSubType.map((kol) => (
                    <option key={kol.id} value={kol.label}>
                      {kol.label}
                    </option>
                  ))}
                </Input>
                {this.validateContactFormField("kolSubType") && (
                  <FormFeedback>Please Select Sub-KOL Type</FormFeedback>
                )}
                <br />
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Country:
                </label>
                <Input
                  type="select"
                  id="SpecilitySelect"
                  multi
                  style={{ backgroundColor: "white" }}
                  value={this.state.nonClinicalKolForm.country[0]?.label}
                  onChange={(e) =>
                    this.handleNonClinicalFormField("country", e.target.value)
                  }
                >
                  <option value="">Select Country</option>
                  {this.state.countryList.map((country) => {
                    // console.log(country)
                    return (<option key={country.value} value={country.label}>
                      {country.label}
                    </option>)
                  })}
                </Input>
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Full Name:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.fullName}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("fullName", e.target.value);
                  }}
                ></input>
                {this.validateContactFormField("fullName") && (
                  <div className="error">Please Enter Full Name</div>
                )}
              </div>

              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your work email:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.workEmail}
                  onChange={(e) => {
                    this.handleNonClinicalFormField(
                      "workEmail",
                      e.target.value
                    );
                  }}
                ></input>
                {this.validateContactFormField("workEmail") && (
                  <div className="error">Please Enter Work Email</div>
                )}
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your work phone:<span class="mandatory">*</span>
                </label>

                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    fontSize: "15px",
                  }}
                  width="50%"
                  country={"in"}
                  value={this.state.nonClinicalKolForm.phone}
                  onChange={(mobile_no) => {
                    this.handleNonClinicalFormField("phone", mobile_no);
                  }}
                />

                {this.validateContactFormField("phone") && (
                  <div
                    style={{ marginTop: "10px !important" }}
                    className="error"
                  >
                    Please Enter Mobile Number
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your company name:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.companyName}
                  onChange={(e) => {
                    this.handleNonClinicalFormField(
                      "companyName",
                      e.target.value
                    );
                  }}
                ></input>
                {this.validateContactFormField("companyName") && (
                  <div className="error">Please Enter Work Email</div>
                )}
              </div>
              <div className="col-lg-12">
                <label for="" className="form-label">
                  Your job title:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.jobTitle}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("jobTitle", e.target.value);
                  }}
                ></input>
                {this.validateContactFormField("jobTitle") && (
                  <div className="error">Please Enter Job Title</div>
                )}
              </div>
              <div className="col-lg-12">
                <label for="exampleInputEmail1" className="form-label">
                  Message:<span class="mandatory">*</span>
                </label>
                <textarea
                  name=""
                  placeholder=""
                  className="form-control"
                  id=""
                  rows="2"
                  value={this.state.nonClinicalKolForm.message}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("message", e.target.value);
                  }}
                ></textarea>

                {this.validateContactFormField("message") && (
                  <div className="error">Please Enter Message</div>
                )}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() =>
                this.setState({
                  nonClinicalContactFormSubmitted: true,
                  showNonClinicalContactForm: false,
                })
              }
              className="btn btn-brand ms-lg-3"
            >
              Submit
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => this.closeNonClinicalContactModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </body>
    );
  }
}

export default home;
