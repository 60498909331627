import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {FormGroup,Input, Label, Button, Spinner ,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Axios from 'axios';
import LoadingBar from "react-top-loading-bar";
// import { FormGroup, Label, Input, Modal, Table, Button, Spinner } from 'reactstrap';

import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import { utils, writeFileXLSX } from 'xlsx';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import Swal from 'sweetalert2';
import ManagerHeader from './ManagerHeader';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

const { ExportCSVButton } = CSVExport;

const headerSortingStyle = { backgroundColor: 'grey' };

const { SearchBar } = Search;

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);


class ManagerDashboard extends Component {
    constructor(props) {

        super(props)

        this.state = {

            system_id: '',
            delete_id: '',

            //To show Spinner
            ShowBar: false,

            manager_id: '',
            manager_name: '',
            manager_email: '',
            client_name:'',
            // For passing to edit project page
            project_id: '',
            p_name: '',
            status_for_editProject: '',

            SpecialityData: [],
            CountryListData: [],
            // export model

            KOLExportModal: false,
            StartDate: '',
            EndDate: '',
            country_id:'',
            specialty_id:'',
      

            // redirect 
            redirectManagerActivity: false,
            redirectIdentificationActivity:false,
            redirectpreliminarykolNav:false,
            redirectAssignkoltoProject:false,
            redirectEditProject: false,
            redirectManagerKolList: false,
            RedirectClientProjectList:false,

            redirectreviewsignup: false,
            redirectClientList:false,
            redirectUploadDeepProfiles:false,
            redirectPersonalInformationBiography:false,
            redirectreviewquote:false,
          

            // project count
            total_count: '0',
            completed_count: '0',
            ongoing_count: '0',
            rejected_count: '0',
            upcoming_count: '0',


            // State for saving data
            Client_list: [],
            idForCreateNewProject: '',

            onPriceFilter: null,
            onProductilters: null,

            // State for displaying data
            status: '',

          
            // Column state for Bootstrap Table 
            columns: [
                {
                    dataField: 'client_id',
                    text: 'Client ID',
                    hidden:true,
                },
                {
                    dataField: 'company_nm',
                    text: 'Company Name',
                    hidden:true,
                },
                {
                    dataField: 'client_name',
                    text: 'Client Name',
                    sort: true,
                    formatter: this.link,
                   filter: textFilter(),

                    filterRenderer: (onFilter, column) => {
                        this.onTextFilter = onFilter;
                        return null;
                    },
                    style: (cell, row, rowIndex, colIndex) => {
                        return {
                            color: '#41A2FF'
                        };
                    },
                },
                {
                    dataField: 'company_nm',
                    text: 'Company Name',
                },
                {
                    dataField: 'designantion',
                    text: 'Designantion',
                }
            
               
            ]
            // End of state
        }
        // End of constructor
    }

    onTextChange = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onTextFilter(value);
    };

    link = (cell, row) => {
        console.log(cell)
        console.log(row)
        this.setState({client_name:row.client_name});
        var client_id = row.client_id;
        return (
            <div>
                <a style={{
           color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }} onClick={this.GoToClientProjectList.bind(this, client_id)}> {row.client_name} </a>
            </div>
        )
    }

    GoToClientProjectList(client_id) {
        console.log('Yep Here');
        console.log('Id Here', client_id);
            this.setState({
                RedirectClientProjectList: true,
            })
            sessionStorage.setItem('client_id', client_id)
            sessionStorage.setItem('manager_name', this.state.manager_name)
            sessionStorage.setItem('client_name', this.state.client_name)
    }

    projectstatuscolour = (cell, row) => {
        var id = row.project_id;
        var project_name = row.p_name;
        var project_status = row.project_status;
        var col='';
      
        if (row.project_status == "work in progress") {
            col= '#FF7F41'
            
                } else if (row.project_status == "Ongoing") {
                        col= '#1FCE39'
            
                } else if (row.project_status == "Upcoming") {
                    
                        col= '#41A2FF'
                    

                } else if (row.project_status == "Completed") {
                    
                        col= '#FF7F41'
                    
                }
      
      
      
        return (
          <div style={{color:col}}>
           {row.project_status}
          </div>
        )
      }


      toggleKOLExportModal() {
      
         this.setState({
            KOLExportModal: !this.state.KOLExportModal,
             //PasswordErr: '',
         });
     }

    linkFollow = (cell, row, rowIndex, formatExtraData) => {
        console.log("linkfollow");
        return (
            <div>
               {/* <button onClick={this.Delete.bind(this, row.project_id)} className="cetas-button cetas-button-small mr-2">
                     Delete
                 </button> */}
            </div>
        );
    };

    // Method to fetch data from API when component Mounted
    componentDidMount = async () => {

        let getSession_Manager_Email = await sessionStorage.getItem('email')
        if (getSession_Manager_Email !== null) {
            await this.setState({
                manager_email: getSession_Manager_Email,
            })
        }
        let getSession_Manager_Name = await sessionStorage.getItem('name')
        if (getSession_Manager_Name !== null) {
            await this.setState({
                manager_name: getSession_Manager_Name,
            })
        }
        let getSession_Manager_ID = await sessionStorage.getItem('id')
        if (getSession_Manager_ID !== null) {
            await this.setState({
                manager_id: getSession_Manager_ID,
            })
        }
        console.log("getSession_Manager_Email ===== ", getSession_Manager_Email)
        console.log("getSession_Manager_ID ===== ", getSession_Manager_ID)
        console.log("getSession_Manager_Name ===== ", getSession_Manager_Name)

        // const options = {
        //     method: 'POST',
        //   }
        //   //  Speciality type data
        //   fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
        //     .then((Response) => Response.json())
        //     .then((findresponse) => {
        //       console.log(findresponse)
        //       var testDataSpeciality = findresponse.data;
        //       console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        //       this.setState({
        //         SpecialityData: findresponse.data,
        //       })
        //     })
      
        //   // Country List
        //   fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
        //     .then((Response) => Response.json())
        //     .then((findresponse) => {
        //       console.log(findresponse)
        //       var TestCountryList = findresponse.Country_Details;
        //       // var TestKolIddata= findresponse.kol_system_id;
        //       console.log("TestCountryList", JSON.stringify(TestCountryList));
        //       // console.log("TestKolIddata",JSON.stringify(TestKolIddata));
        //       this.setState({
        //         CountryListData: findresponse.Country_Details,
        //       })
        //     })
        



        // Project List Fetched
        console.log('Fetchig data with id for project list');
        const url = global.websiteUrl+'/service/Manager/get_client_list';
        let formData = new FormData();
        // formData.append('manager_id', this.state.manager_id);
        formData.append('manager_id', getSession_Manager_ID);
        // formData.append('oper', 'del');

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        Axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data.Client_list;
                console.log("Fetched data of Project list", JSON.stringify(lgdata));
                var lgstatus = response.data.status;
                console.log("Fetched status of Project list", JSON.stringify(lgstatus));
                console.log("Message from fetched api of Project list", response.data.message);
                // var team_members_without_null = response.data.KOL_Personal_Info.team_members_without_null;
                // this.setState({
                //     idForCreateNewProject: response.data.Project_Id,
                // })
                // sessionStorage.setItem('idForCreateNewProject', response.data.Project_Id)
                // console.log("idForCreateNewProject response", response.data.Project_Id);
                // console.log("idForCreateNewProject", this.state.idForCreateNewProject);
                // lgdata.map((data)=>{
                //     this.setState({
                //        projectList: data.team_members_without_null,
                //     })
                // })

                if (lgdata) {
                    this.setState({
                        status: response.data.message,
                        Client_list: response.data.Client_list,
                       // idForCreateNewProject: response.data.Project_Id,
                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        //idForCreateNewProject: response.data.Project_Id,
                        Client_list: []
                    })
                }

            });

        // Dashoard Count Fetched
        console.log('Fetchig data with id for project list');
        const url1 = global.websiteUrl+'/service/Manager/get_Manager_Dash_Count';
        let formData1 = new FormData();
        // formData1.append('manager_id', this.state.manager_id);
        formData1.append('manager_id', getSession_Manager_ID);

        const config1 = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        Axios.post(url1, formData1, config1)
            .then(response => {
                console.log(response);
                var lgdata = response.data;
                console.log("Fetched data", JSON.stringify(lgdata));
                var lgstatus = response.data.status;
                console.log("Fetched status", JSON.stringify(lgstatus));
                console.log("New Project Count", response.data.Project_Count);
                this.setState({
                    total_count: response.data.Project_Count
                })

                console.log("Project Info", response.data.Project_Info)
                var all_count = response.data.Project_Info;

                if (all_count) {
                    all_count.map(async (data) => {
                        if (data.project_status == 'Completed') {
                            this.setState({
                                completed_count: data.pro_count
                            })
                        }
                        if (data.project_status == 'Ongoing') {
                            this.setState({
                                ongoing_count: data.pro_count
                            })
                        }
                        if (data.project_status == 'Upcoming') {
                            this.setState({
                                upcoming_count: data.pro_count
                            })
                        }
                    })
                }
                console.log("ongoing_count", this.state.ongoing_count);
            });


            
        
    }

    
    validate = () => {
        let sdateErr = '';
        let edateErr = '';
        let specialityErr='';
        let countryErr='';
        
        if (!this.state.StartDate) {
          sdateErr = 'Please Enter Start date';
        }
        if (!this.state.EndDate) {
          edateErr = 'Please Enter End date';
        }
         
        if (sdateErr || edateErr ) {
          this.setState({
            sdateErr,edateErr
          });
          return false;
        }
        return true;
      }

    ExportInExcel=()=> 
    {     
        // const isValid = this.validate();
        // if (isValid) {
        if (this.state.country_id !== "" || this.state.specialty_id !="" || (this.state.StartDate !="" && this.state.EndDate != "" )) {
                // alert(this.state.country_id)
                // alert(this.state.specialty_id)
        let ExportableData=[];
        // Export Personal Information and Biography
        const url = global.websiteUrl+'/service/Deep_Profiles/Downloadable_PersonalInfo_Biography_Data';
        let formData = new FormData();
        formData.append('start_date', this.state.StartDate);
        formData.append('end_date', this.state.EndDate);
        formData.append('country_id', this.state.country_id);
        formData.append('specialty_id', this.state.specialty_id);
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
                }

                this.setState({
                    ShowBar: true,
                  })
            
        Axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                ExportableData = response.data.kol_Details;
                //alert(response.data.kol_Details.length)              
                // alert(response.data.status)
                if(response.data.status==true)
                {
                    if(response.data.kol_Details.length>0)
                    {
                    this.exportexcel(ExportableData,"PreliminaryData")                
                        this.setState({
                            ShowBar: false,
                            // progressBar: false,
                            KOLExportModal: false,
            
                            })

                            Swal.fire({
                                icon: 'success',
                                text: 'Download Success',             
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                    }
                    else
                    {
                        this.setState({
                            ShowBar: false,
                            // progressBar: false,
                            KOLExportModal: false,
            
                            })

                            Swal.fire({
                                icon: 'success',
                                text: 'No Record Found in selected date range',             
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                    }

                    }
                    else
                    {
                        this.setState({
                            ShowBar: false,
                            })
                        Swal.fire({
                            icon: 'error',
                            text:'Download failed',
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                          })
                    }


            });

       }
       else 
       {
        this.setState({
            edateErr:'Select any one of these option'
          });
       }
    }

exportexcel=(csvData,fileName)=>{
        //const csvData= this.state.projectList;
        //const fileName="test";

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';    
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Sheet1': ws }, SheetNames: ['Sheet1'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
       
}

    // STARNGE  
    onTextChange = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onTextFilter(value);
    };

    //STRANGE
    onFilter = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onStatusFilters(value);
    };

    // //Funtion to apply links to project names
    // link = (cell, row, rowIndex, formatExtraData) => {
    //     console.log(cell)
    //     console.log(row)
    //     var status = row.project_status;
    //     var project_id = row.project_id;
    //     return (
    //         <div>
    //             <a onClick={this.GoToEditProject.bind(this, project_id, status)}> {row.p_name} </a>
    //         </div>
    //     )
    // }

    // GoToEditProject(project_id, status) {
    //     console.log("in edit project redirect")
    //     console.log("status", status)
    //     // if( status == 'work in progress' || status == 'new' ){
    //     if (status == 'Upcoming' || status == 'Ongoing') {
    //         this.setState({
    //             redirectEditProject: true,
    //             project_id: project_id,
    //             status_for_editProject: status,
    //         })
    //     }
    // }

    linkProjectComment = (cell, row, rowIndex, formatExtraData) => {
        console.log(cell)
        console.log("linkProjectComment",row)
        var project_id = row.project_id;
        var p_name = row.p_name;
        var project_status = row.project_status;
        return (
            <div>
                <a style={{
           color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }} onClick={this.GoToManagerKolList.bind(this, project_id, p_name,project_status)}> {row.p_name} </a>
            </div>
        )
    }

    GoToManagerKolList(project_id, p_name, project_status) {
        console.log("in manager kol list redirect")
        sessionStorage.setItem('project_id', project_id)
        sessionStorage.setItem('p_name', p_name)
        if(project_status == "Upcoming"){
            Swal.fire({
                icon: 'error',
                text: "Project is in upcoming state",
                // text: 'Wrong Username or Password!',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            });     
        }
        else{
            this.setState({
                redirectManagerKolList: true,
                // project_id: project_id, 
                // p_name: p_name,
            })
        }
    }

    // rowEvents = {
    // onClick: (e, row, rowIndex) => {
    //     console.log('clicked on row with index:',rowIndex);
    //         console.log(row)
    //         var project_id = row.project_id;
    //         var p_name = row.p_name;

    //         this.setState({ 
    //             redirectManagerKolList: true,
    //             project_id: project_id, 
    //             p_name: p_name,
    //         })
    // },
    // };

    createProjectNav() {
        this.setState({ redirectCreateProject: true })
    }

    toggleManagerActivity() {
        this.setState({ redirectManagerActivity: true })
    }

    toggleIdentificationActivity() {
        this.setState({ redirectIdentificationActivity: true })
    }

    
    
    managereviewsignup() {
        this.setState({ redirectreviewsignup: true })
    }

    manageClientList()
    {
        this.setState({ redirectClientList: true })
    }

    UploadDeepProfiles() {
        this.setState({ redirectUploadDeepProfiles: true })
    }
    

    UploadBasicandbiography=()=> 
    {     
        //PersonalInformationBiography    
        
        this.setState({redirectPersonalInformationBiography:true})
        
    }

    managereviewquote() {
        this.setState({ redirectreviewquote: true })
    }
    preliminarykolNav()
    {
        this.setState({ redirectpreliminarykolNav: true })

    }

    AssignkoltoProject()
    {
        this.setState({redirectAssignkoltoProject:true})
    }

    actionsEditProjectForm = (cell, row, rowIndex, formatExtraData) => {
        // var status = row.project_status;
        // var project_id = row.project_id;
        if (row.project_status == 'Upcoming') {
            return (
                <div>
                    <button class="cetas-button cetas-button-small"
                        onClick={this.editProjectForm.bind(this, row.project_id, row.project_status)}>
                        Edit
                    </button>
                </div>
            )
        }
        else if (row.project_status == 'Ongoing') {
            return (
                <div>
                    <button class="cetas-button cetas-button-small"
                        onClick={this.editProjectForm.bind(this, row.project_id, row.project_status)}
                    >
                        Manage
                    </button>
                </div>
            )
        }
        return (
            <div>
                {/* <button class="cetas-button cetas-button-small"
                    onClick={this.delete.bind(this, row.project_id, row.project_status)}
                >
                    Delete
                </button> */}
            </div>
        )

    };

    editProjectForm(project_id, status) {
        console.log("In Action Edit Project form", project_id)
        this.setState({
            redirectEditProject: true,
            // project_id: project_id, 
            // status_for_editProject: status,
        })
        sessionStorage.setItem('project_id_for_edit_project', project_id)
        sessionStorage.setItem('status_for_editProject', status)
    }

    render() {

        
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );

    
    let Country_list = this.state.CountryListData;
    let OptionsCountry = Country_list.map((Country_list) =>
      <option key={Country_list.id} value={Country_list.id}>
        {Country_list.country_name}</option>
    );

        if (this.state.redirectManagerActivity) {
            return (<Redirect to={{
                pathname: '/ManagerActivity',
                state:{
                    idForCreateNewProject: this.state.idForCreateNewProject,
                    manager_name: this.state.manager_name,
                    manager_id: this.state.manager_id,    
                } 
            }} />)
        }

        if (this.state.RedirectClientProjectList) {
            return (<Redirect to={{
                pathname: '/ManagerClientProjectList',
            }} />)
        }

        if (this.state.redirectreviewsignup) {
            return (<Redirect to={'/ReviewSignup'} />)
        }

        
        if (this.state.redirectIdentificationActivity) {
            return (<Redirect to={'/IdentificationActivity'} />)
        }
        
        
        if (this.state.redirectClientList) {
            return (<Redirect to={'/ManagerClientList'} />)
        }

        
        
        if (this.state.redirectUploadDeepProfiles) {
            return (<Redirect to={'/UploadDeepProfiles'} />)
        }
        
        if(this.state.redirectPersonalInformationBiography){
            return(<Redirect to={'/UploadPersonalInformationBiography'}/>)
        }

        if (this.state.redirectreviewquote) {
            return (<Redirect to={'/ReviewQuote'} />)
        }

        if (this.state.redirectpreliminarykolNav) {
            return (<Redirect to={{
                pathname: '/UploadPreliminarykol',                
            }} />)
        }
        
        if(this.state.redirectAssignkoltoProject)
        {
            return(<Redirect to={{

                pathname:'/AssignkoltoProject',

            }}/>)

        }

        if (this.state.redirectEditProject) {
            return (<Redirect to={{
                pathname: '/EditProject',
                state:{ 
                    project_id: this.state.project_id,
                    status_for_editProject: this.state.status_for_editProject,
                    manager_name: this.state.manager_name,
                    manager_id: this.state.manager_id,     
                } 
            }} />)
        }

        if (this.state.redirectManagerKolList) {
            return (<Redirect to={{
                pathname: '/ManagerKolList',
                state:{ 
                    project_id: this.state.project_id, 
                    p_name: this.state.p_name,
                    manager_name: this.state.manager_name,
                    manager_id: this.state.manager_id, 
                } 
            }} />)
        }

        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            return (
                <Button outline color="danger" className="mr-2" onClick={handleClick} > {page} </Button>
            );
        };

        const options = {
            pageButtonRenderer,
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }, {
                text: 'All', value: this.state.Client_list.length
            }]  // A numeric array is also available. the purpose of above example is custom the text

        };

        return (
            <div style={{marginTop:'15vh' }}>
                {/* {this.state.ShowBar ?
                    <div style={{ position: 'fixed', top: '0' }}>
                        <LoadingBar
                            progress='100'
                            // onLoaderFinished={() => setProgress(0)}
                            //  progress={progress}
                            height={3} color="#A90007" transitionTime={4000}
                            loaderSpeed={4000} />
                    </div>
                    : null} */}
                     <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}
                <div >
                    <div >
                        <div  >
                            <ManagerHeader ManagerName={this.state.manager_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <h2 className="mb-30" >Manager Dashboard

                                       

                                        <button className="cetas-button float-right"
                                                onClick={this.toggleManagerActivity.bind(this)}>Others</button>

<button className="cetas-button float-right"
                                                onClick={this.toggleIdentificationActivity.bind(this)}>KOL Identification</button>


{/*                                           


                                            <button className="cetas-button float-right"
                                                onClick={this.toggleKOLExportModal.bind(this)}>Export Kol Info</button>
                                            <button className="cetas-button float-right"
                                                onClick={this.preliminarykolNav.bind(this)}>Upload Preliminary Data</button>

                                            <button class="cetas-button float-right" onClick={this.createProjectNav.bind(this)}>
                                                New Project</button>

                                            <button className="cetas-button float-right"
                                                onClick={this.managereviewquote.bind(this)}>Review Quote</button>
                                             <button className="cetas-button float-right"
                                                onClick={this.managereviewsignup.bind(this)}>Review Signup</button>
                                          
                                          <button className="cetas-button float-right"
                                                onClick={this.manageClientList.bind(this)}>Client List</button>
                                           */}
                                        
                                        </h2>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="tile">
                                                        <div class="tile-heading"> {/*  style={{marginTop:'14%'}} */}
                                                            <h3>Total <br></br> Projects</h3>
                                                            <div class="red">
                                                                <h2 class="bold" style={{color:'#C70039', textDecoration:'none'}}>{this.state.total_count}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="tile">
                                                        <div class="tile-heading">
                                                            <h3>Completed <br></br> Projects</h3>
                                                            <div class="red">
                                                                <h2 class="bold" style={{color:'#C70039', textDecoration:'none'}}>{this.state.completed_count}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="tile">
                                                        <div class="tile-heading"> {/*  style={{marginTop:'14%'}} */}
                                                            <h3>Ongoing <br></br> Projects</h3>
                                                            <div class="red">
                                                                <h2 class="bold" style={{color:'#C70039', textDecoration:'none'}}>{this.state.ongoing_count}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="tile">
                                                        <div class="tile-heading"> {/*  style={{marginTop:'14%'}} */}
                                                            <h3>Upcoming <br></br> Projects</h3>
                                                            <div class="red">
                                                                <h2 class="bold" style={{color:'#C70039', textDecoration:'none'}}>{this.state.upcoming_count}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <hr /> */}

                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <h3 className="mb-30">Client List</h3>
                                                            <div className="employee-actions">
                                                                <div className="table-infobox">
                                                                    {/* <p className="list-count" style={{ color: '#000000' }}><b>{this.state.Client_list.length} Clients</b></p> */}
                                                                    <div className="filter-box float-right mb-30">
                                                                        <div className="input-group" style={{ marginLeft: '40%' }}>
                                                                            <div className="input-group-prepend">
                                                                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                            </div>
                                                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by Client Name"
                                                                                onChange={(e) => { this.onTextChange(e) }}
                                                                            />
                                                                        </div>
                                                                        {/* <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                            </div>
                                                                            <select className="form-control no-border-top no-border-left no-border-right" id="exampleFormControlSelect1"
                                                                                onChange={(e) => { this.onTextChange(e) }}
                                                                            >
                                                                                <option value="">Sort by alphabates</option>
                                                                                <option>A</option>
                                                                                <option>B</option>
                                                                                <option>C</option>
                                                                                <option>D</option>
                                                                            </select>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="sort-roll">
                                                                    {/* <select className="form-control" id="exampleFormControlSelect1"
                                                                        onChange={(e) => { this.onFilter(e) }}
                                                                    >
                                                                        <option value="">Select Status</option>
                                                                        <option>Ongoing</option>
                                                                        <option>Upcoming</option>
                                                                        <option>Completed</option>
                                                                    </select> */}
                                                                </div>
                                                            </div>
                                                            {/* <ToolkitProvider
                                                                keyField="emp_id"
                                                                data={this.state.projectList}
                                                                columns={this.state.columns}
                                                                
                                                                search={this.customMatchFunc}
                                                                //exportCSV
                                                                exportCSV={{
                                                                    fileName: 'cetas.csv',
                                                                    noAutoBOM: false,
                                                                }}
                                                            >
                                                                {
                                                                    props => ( */}
                                                            <div style={{ marginTop: '7%' }}>
                                                                <BootstrapTable
                                                                    // {...props.baseProps}
                                                                    noDataIndication="Table is Empty"
                                                                    keyField="emp_id"
                                                                    data={this.state.Client_list}
                                                                    columns={this.state.columns}
                                                                    striped
                                                                    hover
                                                                    condensed
                                                                    loading={true}
                                                                    pagination={paginationFactory(options)}
                                                                    filter={filterFactory()}
                                                                    rowStyle={ { backgroundColor: 'White' } }

                                                                />
                                                            </div>
                                                            {/* )
                                                                }
                                                            </ToolkitProvider> */}
  {/* export model */} 
  {/* toggle={this.toggleEditIncentiveModal.bind(this)} */}
        <Modal isOpen={this.state.KOLExportModal}  centered>
                                        <ModalHeader toggle={this.toggleKOLExportModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h4 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Select Information and Date Range </h4>
                                        </ModalHeader>
                                        <ModalBody style={{ padding: '0px 80px 0px 80px' }}>

                                            <FormGroup>
                           
                     <div className="form-group" style={{ marginTop: '3%' }}>
                     
                            {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                  Country <span class="mandatory">*</span></label> */}
                                <Input type="select" id="roleSelect" className="form-control"
                                  style={{ height: '50px' }}
                                  name="country_id"
                                //   value={this.state.PersonalInformation.country_id}
                                  onChange={async (e) => {
                                    await this.setState({ country_id:e.target.value });
                                    // let { PersonalInformation, checkDuplicate } = this.state;
                                    // PersonalInformation.country_id = e.target.value;
                                    // checkDuplicate.country_id = e.target.value;
                                    // PersonalInformation.NPI_no = '';
                                    // await this.setState({ PersonalInformation, checkDuplicate });

                                  }}
                                >
                                  <option value="" >--Select Country--</option>
                                  {OptionsCountry}
                                </Input>
                                <div style={{
                                  color: "red", fontSize: "12px", marginTop: "1%",
                                  marginBottom: "5px",
                                }}>{this.state.countryErr}</div>
                     </div>      
                     <div className="form-group" style={{ marginTop: '2%' }}>

                                  {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                    Speciality <span class="mandatory">*</span></label> */}
                                  <Input type="select" id="roleSelect" className="form-control" 
                                    style={{ height: '50px' }}
                                    name="specialty_id"
                                    // value={this.state.PersonalInformation.specialty_id}
                                    onChange={async (e) => {
                                        await this.setState({ specialty_id:e.target.value });
                                   
                                    //   let { PersonalInformation, checkDuplicate } = this.state;
                                    //   PersonalInformation.specialty_id = e.target.value;
                                    //   checkDuplicate.specialty_id = e.target.value;
                                    //   await this.setState({ PersonalInformation, checkDuplicate });

                                    
                                    }}
                                  >
                                    <option value="" >--Select Speciality--</option>
                                    {OptionsSpeciality}
                                  </Input>
                                  


                                  <div style={{
                                    color: "red", fontSize: "12px", marginTop: "1%",
                                    marginBottom: "5px",
                                  }}>
                                    {this.state.specialityErr}</div>
                     </div>
                    
                 <div className="form-group" style={{ marginTop: '2%' }}>
                            {/* <label for="exampleFormControlTextarea1">Start Date</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              From Date </label>
                            <input type="date"
                            //   min={new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()}
                              className="form-control" id="exampleInputPassword1"
                              placeholder="Enter Product Name"
                              value={this.state.StartDate} onChange={(e) => {
                                let { StartDate } = this.state;
                                StartDate = e.target.value;
                                this.setState({ StartDate });
                              }}
                            />
                          </div>
                          <div className="loginErrCreateProject">{this.state.sdateErr}</div>

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {/* <label for="exampleFormControlTextarea1">End Date</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              To Date </label>
                            <input type="date"
                              min={new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()}
                              className="form-control" id="exampleInputPassword1"
                              placeholder="Enter Product Name"
                              value={this.state.EndDate} onChange={(e) => {
                                let { EndDate } = this.state;
                                EndDate = e.target.value;
                                this.setState({ EndDate });
                              }}
                            />
                          </div>
                          <div className="loginErrCreateProject">{this.state.edateErr}</div>

                                               
                        </FormGroup>                                         

                                        </ModalBody>
                                        <ModalFooter style={{ borderTop: '0px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                                onClick={this.toggleKOLExportModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '30%', }}
                                                onClick={this.ExportInExcel.bind(this)}>
                                                Export
                                            </button>

                                        </ModalFooter>
                                    </Modal>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagerDashboard;