import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
    textFilter, customFilter, selectFilter, Comparator,
    FILTER_TYPES
} from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import AdminHeader from './AdminHeader';

import Download from './Download';
import UploadExcel from './UploadExcel';

class ConditionCurd extends Component {
    constructor(props) {
        super(props);
        this.state = {

            ShowProcedure: false,
            ShowBar: false,
            UploadModal:false,

            ShowTable: false,

            // to fetch data from api
            searchQuery: '',
          
            SpecialityList: [],
            filteredSpecialities:[],
           
            subSpecialitydata: [],
            SubSpecialityList: [],

            // Set Procedure List Title on Table of procedure and sub speciality
            SubSpecialityTypeName: '',

            // this unique id is for add update and delete operations of procedure and sub speciality
            speciality_id: '',

            // To toggle Add modals
            addSubSpecialityModal: false,

            // to pass data from add modals
            newDataSubSpeciality: {
                sub_speciality_type: '',
            },

            // For validations of add modal
            SubSpecialityAddErr: '',

            //  To toggle Edit modals
            editSubSpecialityModal: false,

            // to pass data from edit modals
            editSubSpecialityData: {
                id: '',
                maping_condition: '',
            },

            // For validations of edit modal
            SubSpecialityEditErr: false,

            columnsSubSpeciality: [
                {
                    dataField: 'id',
                    text: 'ID',
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                    return { width: '20%', textAlign: 'center' };
                    },
                },
                {
                    dataField: 'maping_condition',
                    text: 'Condition Type',
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '40%', textAlign: 'center' };
                    },
                    filter: textFilter(),

                    filterRenderer: (onFilter, column) => {
                        this.onItemFilter = onFilter;
                        return null;
                    },

                },
                {
                    dataField: "Actions",
                    text: "Actions",
                    formatter: this.actionSubSpeciality,
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '30%', textAlign: 'center' };
                    },
                }
            ],


        };
    }

    componentDidMount() {
        this.setState({
            ShowBar: true,
        })
        const headers = new Headers()

        const options = {
            method: 'POST',
        }

        fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var testDataSpecialityList = findresponse.data;
                console.log("testDataSpecialityList", JSON.stringify(testDataSpecialityList));
                this.setState({
                    SpecialityList: findresponse.data,
                    filteredSpecialities:findresponse.data,
                })
                this.setState({
                    ShowBar: false,
                })
            })
    }

    SubSpecialityItemSelectedFunc = async (event) => {
        console.log("list item selected", event.target);
        console.log("list item selected id", event.target.value);
        console.log("list item selected name", event.target.type);

        await this.setState({
            ShowTable: true,
            SubSpecialityTypeName: event.target.type,
            speciality_id: event.target.value,
        });

        this.fetchSubSpecialityData();

    }

    // To fetch Data from sub speciality type tables
    fetchSubSpecialityData() {
        console.log(" in sub speciality Func");
        console.log("sub speciality id", this.state.speciality_id);
        this.setState({
            ShowProcedure: true,
        })
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.speciality_id);
        formData.append('search_type', "Condition");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var subSpecialitydata = response.data;
                console.log("subSpecialitydata", JSON.stringify(subSpecialitydata));
                var SpecialityStatus = response.data.status;
                console.log("SpecialityStatus", JSON.stringify(SpecialityStatus));
                //alert(response.data.statusCode);
                if (SpecialityStatus == false || SpecialityStatus == undefined ) {
                    Swal.fire({
                        icon: 'error',
                        title: 'No data to show for this Category',
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        SubSpecialityList: [],
                    })
                }
                else {
                    this.setState({
                        SubSpecialityList: response.data.data,
                    })
                    console.log("SubSpecialityList", this.state.SubSpecialityList)
                }
                this.setState({
                    ShowProcedure: false,
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    onItemChange = (event) => {
        const { value } = event.target;
        this.onItemFilter(value);
    };


     // Handle search query change
 handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    
    this.setState({ searchQuery }, this.filterSpecialities);
  };

  // Filter specialities based on search query
  filterSpecialities = () => {
    const { searchQuery, SpecialityList } = this.state;
    const filteredSpecialities = SpecialityList.filter(item =>
      item.speciality_type.toLowerCase().includes(searchQuery.toLowerCase())
    );
   // alert(filteredSpecialities[1]["speciality_type"]);
    this.setState({ filteredSpecialities });
  };


    // / Sub Speciality All functions
    toggleaddSubSpecialityModal = () => {
        this.setState({
            addSubSpecialityModal: !this.state.addSubSpecialityModal,
            SubSpecialityAddErr: '',
        })
    }

    validateInAddSubSpeciality = () => {
        let SubSpecialityAddErr = '';
        if (!this.state.newDataSubSpeciality.sub_speciality_type) {
            SubSpecialityAddErr = 'Please Enter Item name';
        }
        if (SubSpecialityAddErr) {
            this.setState({ SubSpecialityAddErr });
            return false;
        }
        return true;
    }


    toggle_download_page=()=>
        {
            this.setState({
                ShowBar: true,
                ShowProcedure:true,
            })
    
         // this.Export_to_Excel(this.state.SpecialityList,"Speciality List");
            Download("Condition List");
    
        }
    

        toggleUploadModal=()=>
            {       
              sessionStorage.setItem("UploadType","Condition"); 
               
               this.setState({
                        UploadModal:!this.state.UploadModal,
               })
        
            }

    subSpecialityAdd() {
        const isValid = this.validateInAddSubSpeciality();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            const url = global.websiteUrl+'/service/Procedure_Speciality/condition_curd';

            let formData = new FormData();
            formData.append('oper', "add");
            formData.append('speciality_id', this.state.speciality_id);
            formData.append('maping_condition', this.state.newDataSubSpeciality.sub_speciality_type);

            const config = {
                // headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then(response => {
                    console.log(response.data);
                    this._refreshSubSpeciality();
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (response.data.status == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            this.setState({
                addSubSpecialityModal: false, newDataSubSpeciality: {
                    sub_speciality_type: '',
                }
            });
        }
    }

    actionSubSpeciality = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <button class="cetas-button cetas-button-small"
                    onClick={this.editSubSpeciality.bind(this, row.id, row.maping_condition,)}>
                    Edit
                </button>
                &nbsp;&nbsp;
                <button class="cetas-secondry-button cetas-button-small"
                    onClick={this.deleteSubSpeciality.bind(this, row.id)}>
                    Delete
                </button>
            </div>
        );
    };

    editSubSpeciality(id, maping_condition) {
        this.setState({
            editSubSpecialityData: { id, maping_condition },
            editSubSpecialityModal: !this.state.editSubSpecialityModal
        });
    }

    toggleeditSubSpecialityModal() {
        this.setState({
            editSubSpecialityModal: !this.state.editSubSpecialityModal,
            SubSpecialityEditErr: '',
        });
    }

    validateSubSpecialityEdit = () => {
        let SubSpecialityEditErr = '';
        if (!this.state.editSubSpecialityData.maping_condition) {
            SubSpecialityEditErr = 'Please Enter Condition name';
        }
        if (SubSpecialityEditErr) {
            this.setState({ SubSpecialityEditErr });
            return false;
        }
        return true;
    }

    updateSubSpeciality() {
        const isValid = this.validateSubSpecialityEdit();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            console.log("in edit modal")
            console.log("maping_condition", this.state.editSubSpecialityData.maping_condition);
            console.log("id", this.state.editSubSpecialityData.id);
            if (isValid) {
                const url = global.websiteUrl+'/service/Procedure_Speciality/condition_curd';

                let formData = new FormData();
                formData.append('oper', "edit");
                formData.append('id', this.state.editSubSpecialityData.id);
                formData.append('maping_condition', this.state.editSubSpecialityData.maping_condition);
                formData.append('speciality_id', this.state.speciality_id)

                const config = {
                    // headers: { 'content-type': 'multipart/form-data' }
                }

                axios.post(url, formData, config)
                    .then(response => {
                        console.log(response.data);
                        this._refreshSubSpeciality();
                        this.setState({
                            ShowProcedure: false,
                        })
                        if (response.data.status == true) {
                            Swal.fire({
                                icon: 'success',
                                text: response.data.message,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: response.data.message,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

                this.setState({
                    editSubSpecialityModal: false, editSubSpecialityData: {
                        id: '',
                        maping_condition: '',
                    }
                });
            }
        }
    }

    // deleteSubSpeciality(id) {
    //     console.log(id);
    //     let idtoDelete = JSON.parse(id);
    //     const url = global.websiteUrl+'/service/Sub_Speciality/sub_speciality_curd';

    //     let formData = new FormData();
    //     formData.append('oper', "del");
    //     formData.append('id',idtoDelete);

    //      const config = {     
    //             // headers: { 'content-type': 'multipart/form-data' }
    //         }

    //         axios.post(url, formData, config)
    //             .then(response => {
    //                 console.log(response.data);
    //                 this._refreshSubSpeciality();
    //                 if(response.data.status == true)
    //                 {
    //                   Swal.fire({
    //                     icon: 'success',
    //                     text: response.data.message,
    //                     confirmButtonColor: '#d33',
    //                     confirmButtonText: 'OK'
    //                   })
    //                 }
    //                 else{
    //                   Swal.fire({
    //                     icon: 'error',
    //                     text: response.data.message,
    //                     confirmButtonColor: '#d33',
    //                     confirmButtonText: 'OK'
    //                   })
    //                 }
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             });
    // }

    deleteSubSpeciality(id) {
        console.log(id);
        let idtoDelete = JSON.parse(id);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success ',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {

            if (result.value) {
                this.setState({
                    ShowProcedure: true,
                })
                console.log(id);
                let idtoDelete = JSON.parse(id);
                const url = global.websiteUrl+'/service/Procedure_Speciality/condition_curd';

                let formData = new FormData();
                formData.append('oper', "del");
                formData.append('id', idtoDelete);

                const config = {
                    // headers: { 'content-type': 'multipart/form-data' }
                }

                axios.post(url, formData, config)
                    .then(response => {
                        console.log(response.data);
                        this._refreshSubSpeciality();
                        if (response.data.status == true) {
                            Swal.fire({
                                icon: 'success',
                                text: response.data.message,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: response.data.message,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                        }
                        this.setState({
                            ShowProcedure: false,
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    });

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // swalWithBootstrapButtons.fire(
                //   'Cancelled',
                //   'Your imaginary file is safe :)',
                //   'error'
                // )
            }
        })
    }

    _refreshSubSpeciality() {
        console.log("in refresh of sub specialty");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.speciality_id);
        formData.append('search_type', "Condition");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var subSpecialitydata = response.data;
                console.log("subSpecialitydata", JSON.stringify(subSpecialitydata));
                var SpecialityStatus = response.data.status;
                console.log("SpecialityStatus", JSON.stringify(SpecialityStatus));
                this.setState({
                    SubSpecialityList: response.data.data,
                })
            })
            .catch(error => {
                console.log(error);
            });
    }


    render() {
        const { filteredSpecialities, searchQuery } = this.state;
        return (
            <body>
                <div style={{marginTop:'15vh'}}>

                    {this.state.ShowBar ? <div style={{ position: 'fixed', top: '0' }}>
                        <LoadingBar
                            progress='100'
                            // onLoaderFinished={() => setProgress(0)}
                            //  progress={progress}
                            height={3} color="#A90007" transitionTime={4000}
                            loaderSpeed={4000} />
                    </div>
                        : null}

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40' loading='true' />
                    </Modal> : null}
                    <div >
                        <div >
                            <AdminHeader />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div className="back-section">
                                            <a>
                                                <Link to="/AdminDashboard">
                                                    <img
                                                        src={require('../Assets/Images/back-arrow.svg')}
                                                    />
                                                    Back
                                                </Link>
                                            </a>
                                        </div>

                                        <button className="cetas-button float-right"
                                                            data-toggle="modal" data-target="#new-empl-add"
                                                            onClick={this.toggleUploadModal.bind()}
                                                           >Upload Condition List</button>

                                        <button className="cetas-button float-right"
                                                            data-toggle="modal" data-target="#new-empl-add"
                                                            onClick={this.toggle_download_page.bind()}
                                                           >Download Condition List</button>
                                                       
                                                       <h2>Condition Management</h2>
                                      
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                                            <div className="sidebar" >
                                                <ul className="cetas-leftnav">

                                                    <li class="submenu-link">
                                                        <a onClick={this.SubSpeciality}>
                                                            <text style={{ color: '#A90007', fontWeight: 'bold' }}>Speciality Types</text>
                                                            <img style={{ marginLeft: '10%' , width:'20px' , height:'20px' }}
                                                                src={require('../Assets/Images/back-arrow.svg')}
                                                            />
                                                        </a>

                                                        <input
        type="text"
        placeholder="Search by Speciality Type..."
        value={searchQuery}
        onChange={this.handleSearchChange}
        style={{ marginBottom: "10px", padding: "5px", marginTop:'10px' }}
      />
                                                        {
                                                            <ul>
                                                                {this.state.filteredSpecialities.map(item => (
                                                                    <li value={item.id} type={item.speciality_type}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={this.SubSpecialityItemSelectedFunc} key={item.id}
                                                                    >
                                                                        {item.speciality_type}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        }
                                                    </li>
                                                </ul>
                                                <div style={{ marginTop: '10px' }}></div>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 col-xs-12 profile-box">
                                            <div class="form-rightsec">
                                                {/* Sub Speciality */}
                                                {
                                                    (this.state.ShowTable) ? (
                                                        <div>
                                                            <div className="screen-headingbox">
                                                                <h3>{this.state.SubSpecialityTypeName}
                                                                    {/* <button className="cetas-button float-right"
                                                                        data-toggle="modal" data-target="#new-empl-add"
                                                                        onClick={this.toggleaddSubSpecialityModal.bind()}
                                                                    >+ Add Item</button> */}</h3>
                                                            </div>
                                                            {
                                                                (this.state.SubSpecialityList.length > 0) ? (
                                                                    <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                                                        <div className="employee-actions">
                                                                            <div className="table-infobox">
                                                                                <p className="list-count">
                                                                                    {this.state.SubSpecialityList.length} Items listed
                                                                                </p>
                                                                                <div className="filter-box float-right mb-30" style={{ marginTop: '1.5%' }}>
                                                                                    <div style={{ width: '70%', }} className="input-group float-right">
                                                                                        <div className="input-group-prepend">
                                                                                            <div className="input-group-text">
                                                                                                <img src={require("../Assets/Images/search.svg")} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <input type="text" className="form-control"
                                                                                            id="inlineFormInputGroupUsername"
                                                                                            placeholder="Search by Name"
                                                                                            onChange={(e) => { this.onItemChange(e) }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginTop: '7%' }}>
                                                                            <BootstrapTable keyField='id'
                                                                                data={this.state.SubSpecialityList}
                                                                                columns={this.state.columnsSubSpeciality}
                                                                                filter={filterFactory()}
                                                                                striped
                                                                                hover
                                                                                condensed
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                                                        <div className="employee-actions">
                                                                            <div className="table-infobox">
                                                                                <p className="list-count">
                                                                                    {this.state.SubSpecialityList.length} Items listed
                                                                                </p>
                                                                                <div className="filter-box float-right mb-30">
                                                                                    <div style={{ width: '70%', }} className="input-group float-right">
                                                                                        <div className="input-group-prepend">
                                                                                            <div className="input-group-text">
                                                                                                <img src={require("../Assets/Images/search.svg")} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <input type="text" className="form-control"
                                                                                            id="inlineFormInputGroupUsername"
                                                                                            placeholder="Search by Name"
                                                                                            onChange={(e) => { this.onItemChange(e) }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <BootstrapTable keyField='id'
                                                                            data={[]}
                                                                            noDataIndication="Table is Empty"
                                                                            columns={this.state.columnsSubSpeciality}
                                                                            filter={filterFactory()}
                                                                            striped
                                                                            hover
                                                                            condensed
                                                                        />
                                                                    </div>
                                                                )
                                                            }

                                                            <div style={{ marginBottom: '30%' }}></div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ marginTop: '25%', marginLeft: '20%' }}>
                                                            <h4>Please Select Speciality</h4>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <Modal isOpen={this.state.UploadModal}
                                toggle={this.toggleUploadModal.bind(this)}
                                centered>
                                                
                               <ModalHeader
                            toggle={this.toggleUploadModal.bind(this)}
                            cssModule={{
                                "modal-title": "w-100 text-center",
                                "border-bottom": "0px",
                            }}
                            style={{ borderBottom: "0px" }}
                            >
                            <h3
                                style={{ justifyContent: "center", marginTop: "10px" }}
                                id="login-tit"
                            >
                                
                            </h3>
                            </ModalHeader>

                            <ModalBody style={{ padding: "0px 70px 0px 70px" }}>
                            <UploadExcel></UploadExcel>
                            </ModalBody>
                                
                   
                                </Modal>




                                {/* Add Sub Speciality Type Modal*/}
                                <Modal isOpen={this.state.addSubSpecialityModal} toggle={this.toggleaddSubSpecialityModal.bind(this)}>
                                    <ModalHeader toggle={this.toggleaddSubSpecialityModal.bind(this)}
                                        cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                        style={{ borderBottom: '0px' }}>
                                        <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Add New Item</h3>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label for="AddIntOnco">Add New Condition</Label>
                                            <Input id="title" value={this.state.newDataSubSpeciality.sub_speciality_type} onChange={(e) => {
                                                let { newDataSubSpeciality } = this.state;
                                                newDataSubSpeciality.sub_speciality_type = e.target.value;
                                                this.setState({ newDataSubSpeciality });
                                            }} />
                                            <div style={{ fontSize: 12, color: "red" }}>{this.state.SubSpecialityAddErr}</div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                                            onClick={this.subSpecialityAdd.bind(this)}
                                        >Add Item</button>
                                        <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                            onClick={this.toggleaddSubSpecialityModal.bind(this)}>Cancel</button>
                                    </ModalFooter>
                                </Modal>

                                {/*  Edit Sub Speciality Modal*/}
                                <Modal isOpen={this.state.editSubSpecialityModal} toggle={this.toggleeditSubSpecialityModal.bind(this)} >
                                    <ModalHeader toggle={this.toggleeditSubSpecialityModal.bind(this)}
                                        cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                        style={{ borderBottom: '0px' }}>
                                        <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Edit Item</h3>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label for="category">Update {this.state.SubSpecialityTypeName}</Label>
                                            <Input id="title" value={this.state.editSubSpecialityData.maping_condition} onChange={(e) => {
                                                let { editSubSpecialityData } = this.state;
                                                editSubSpecialityData.maping_condition = e.target.value;
                                                this.setState({ editSubSpecialityData });
                                            }} />
                                            <div style={{ fontSize: 12, color: "red" }}>{this.state.SubSpecialityEditErr}</div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                                            onClick={this.updateSubSpeciality.bind(this)}>Update</button>
                                        <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                            onClick={this.toggleeditSubSpecialityModal.bind(this)}>Cancel</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>




                    <footer class="footer-fixed">
                        <div class="cetas-footer">
                            <div class="container">
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </body>
        );
    }
}

export default ConditionCurd;