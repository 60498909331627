module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://api-node.themesbrand.website",
  },
  jwt:{
    key:"1axBCFCo6y7WD18QM15OJMks0JiVpijwMRi8BlaYppByWjiJ2gj",
   // expiresIn:'5m',
    expiresIn:'60000m',
    session_expired_msg:'Your session has expired. Please login again.',
    session_expired_msg_title:'Oops...',
    wrong_url_msg:'Your session has expired. Please login again.',
   
  }
};