import React, { Component, useRef, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import PhoneInput from 'react-phone-input-2';
import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import '../landingpage.scss';
import LoginHeader from './Loginheader';
import Pagefooter from './footer';

var jwt = require('jsonwebtoken');
const jwtkey=require('../config');

const bcrypt=require('bcryptjs');
          
              
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectNewSignup: false,
      redirectsetpassword: false,
      redirectHome: false,
      redirectForgotpass: false,


      redirectAdmin: false,
      redirectAdministrator: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,

      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,


      kdidentificationhidediv: false,
      kdprofilinghidediv: false,
      kdinteracthidediv: false,


      loginModal: false,
      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,
      redirectClientViewKolList: false,

      //resercherd id for redirectig to researcher dashoard
      RID: '',
      resercher_name: '',

      // Manager info
      manager_id: '',
      manager_name: '',

      // Employee info
      employee_id: '',
      employee_name: '',

      // For validations
      UseremailErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      wrngUsPsErr: '',

      clientUsernameErr: '',
      clientPasswordErr: '',

      administratorUsernameErr: '',
      administratorPasswordErr: '',

      visitorUsernameErr: '',
      visitorPasswordErr: '',

      kolProfileUsernameErr: '',
      kolProfilePasswordErr: '',
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      loginData: {
        email_id: '',
        password: '',
        login_type:'',      
      },

      kolLoginIdForTerms: '',

      contact_us: {
        first_name: '',
        last_name: '',
        organization: '',
        email_id: '',
        phone_no: '',
        country: '',
        learn_qpharma: '',
        representative_contact_me: '',
        qpharmas_products: '',
        technical_support_issue: '',
        need_of_assistance: '',
        receive_qpharma_news: '',
        describe_issue_question: '',
      },
      cuFirstNameErr: '',
      cuLastNameErr: '',
      cuOrganizaErr: '',
      cuEmailErr: '',
      cuPhoneErr: '',
      cuCountryErr: '',

      new_guru: {
        first_name: '',
        last_name: '',
        mobile_no: '',
        email_id: '',
        speciality: '',
        sub_speciality: '',
        procedure_type: '',
        country: '',
        state: '',
        current_place_of_work: '',
        department: '',
        linkedin_link: '',
        additional_info: '',
      },
      ngFirstNameErr: '',
      ngLastNameErr: '',
      ngEmailErr: '',
      cuPhoneErr: '',
      ngCountryErr: '',
      ngStateErr: '',

      new_client: {
        first_name: '',
        last_name: '',
        email_id: '',
        mobile_no: '',
        msg: '',
      },
      ncFirstNameErr: '',
      ncLastNameErr: '',
      ncEmailErr: '',
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: '',
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: '',
        role: '',
      }
    };

  }


  componentDidMount = async () => {
    console.log('in componentDidMount');

    

  }
 
  //login = toggle validate and login
  toggleLoginModal() {
    //console.log("loginclicked")
    this.setState({
      loginModal: !this.state.loginModal,
      UseremailErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      MySProfilePropover: false,
    });
  }

  toggleWithoutidModal() {

    this.setState({ redirectSignup: true })

  }


  redirectsetpassword() { this.setState({ redirectsetpassword: true }) }

  redirectNewSignup() {
    // alert('Signup');
    this.setState({ redirectNewSignup: true })
  }

  redirectForgotpass() {

    this.setState({ redirectForgotpass: true })
  }

  validate = () => {
    let UseremailErr = '';
    let PasswordErr = '';

    if (!this.state.loginData.email_id) {
      UseremailErr = 'Please Enter Registerd Email ID';
    }
    if (!this.state.loginData.password) {
      PasswordErr = 'Please Enter Password';
    }
    if (UseremailErr || PasswordErr) {
      this.setState({ UseremailErr, PasswordErr });
      return false;
    }
    return true;
  }


  login_user= async () => {

    sessionStorage.removeItem('email');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('wtoken');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('login_type');
    sessionStorage.removeItem('project_id');
    sessionStorage.removeItem('kol_id');
    localStorage.removeItem('SearchedProfileData');
    sessionStorage.removeItem("speciality_id");
    
    
    console.log("email_id", this.state.loginData.email_id)
    // console.log("this.state.loginData.password", this.state.loginData.password)
    const hashedPassword = await bcrypt.hash( this.state.loginData.password,'$2a$10$CwTycUXWue0Thq9StjUM0u');  
    //console.log('hashedPassword',hashedPassword);
    const url = global.websiteUrl + '/service/User/user_login';
    let formData = new FormData();
    formData.append('email_id', this.state.loginData.email_id);
    formData.append('password',hashedPassword); 
    this.setState({
      ShowBar: true,
    })
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data.LoginInfo;
         console.log("lgdata===========", JSON.stringify(lgdata));
        var lgstatus = response.data.status;
        // console.log("lgstatus", JSON.stringify(lgstatus));
        // console.log("response message2", response.data.message)
        // console.log("token=", JSON.stringify(response.data.LoginInfo.token));
        // console.log("Information=", JSON.stringify(response.data.LoginInfo.UserInfo));
       // console.log("Name=", JSON.stringify(response.data.LoginInfo.UserInfo.login_type));

      
        if (lgstatus == true) {
              
               // console.log("token=", JSON.stringify(response.data.LoginInfo.token));         
                sessionStorage.setItem('token', response.data.LoginInfo.token);        
                //console.log("email=", JSON.stringify(response.data.LoginInfo.UserInfo.email));
                sessionStorage.setItem('email', response.data.LoginInfo.UserInfo.email);
                //console.log("id=", JSON.stringify(response.data.LoginInfo.UserInfo.id));
                sessionStorage.setItem('id', response.data.LoginInfo.UserInfo.id);
                //console.log("Name=", JSON.stringify(response.data.LoginInfo.UserInfo.name));
                sessionStorage.setItem('name',response.data.LoginInfo.UserInfo.name);
               // console.log("login_type=", JSON.stringify(response.data.LoginInfo.UserInfo.login_type));
                sessionStorage.setItem('login_type',response.data.LoginInfo.UserInfo.login_type);

                const payload= {"email_id": response.data.LoginInfo.UserInfo.email,"password":this.state.loginData.password,"login_type":response.data.LoginInfo.UserInfo.login_type};

                //console.log('logindata',payload);
                
                jwt.sign(payload,jwtkey.jwt.key,{expiresIn: jwtkey.jwt.expiresIn},(err,result)=>
                {
                  if(!err)
                  {
                        console.log('wwwtoken',result);
                        sessionStorage.setItem('wtoken',result);   
                      
                        var login_type =response.data.LoginInfo.UserInfo.login_type;
                        
                        console.log('type1',login_type);

                        var cutchar = (response.data.LoginInfo.UserInfo.id.substring(0, 5)).toUpperCase();
                        switch (cutchar) {
                          case "CHCEM":
                            {                                  
                              if (login_type == "admin")
                              {
                                  this.setState({ redirectAdmin: true })
                              }                      
                              else if (login_type == "researcher") {
                                 this.setState({redirectResercher: true})
                    
                              }
                              else if (login_type == "project manager") {      
                                  this.setState({redirectManager: true})
                    
                              }
                              else if (login_type == "employee") {
                    
                                  this.setState({ redirectEmployee: true})
                              }
                    
                              return;
                    
                            }
                          case "CHCCI":
                            {
                                // if (login_type == "client") {
                                //   this.setState({ redirectClientDashboard: true })
                                // }
                                // else if (login_type == "pre_client") {
                                //   this.setState({ redirectPClientDashboard: true })
                                //   // "client_demo"
                                // } else if (login_type == "client_demo") {
                                //   sessionStorage.setItem('login_type', login_type)
                                //   this.setState({ redirectClientViewKolList: true })
                                //   // "client_demo"
                                // }
                    
                              return;
                            }
                        
                        }
                    


                  }
                  else
                  {
                    console.error('error',err);
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Wrong Username or Password!',
                      // showCancelButton: true,
                      confirmButtonColor: '#d33',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
          
                    this.setState({ ShowBar:false, })
                  }

                });
                
               
        }
        else if (lgstatus == false) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Wrong Username or Password!',
            // showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          })

          this.setState({ ShowBar:false, })

        }
      })
      .catch(error => {
        console.log(error);
      });

   
  }

  makespaceondivkdidentification() {

    this.setState({

      hidedivkdprofiling: false,
      hidedivkdinteract: false,
      hidedivkdidentification: true,



    });

  }

  makespaceondivkdprofiling() {

    this.setState({

      hidedivkdidentification: false,
      hidedivkdinteract: false,
      hidedivkdprofiling: true,

    });

  }


  makespaceondivkdinteract() {

    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: true,

    });

  }



  makespaceondivhome() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: false,

    });
  }


  render() {

    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }

    if (this.state.redirectHome) {
      return (<Redirect to={'/Home'} />)
    }

    if (this.state.redirectSignup) {
      return (<Redirect to={'/create new account'} />)
    }

    if (this.state.redirectNewSignup) {
      return (<Redirect to={'/create new account'} />)
    }

    if (this.state.redirectsetpassword) {
      return (<Redirect to={'/update password'} />)
    }


    if (this.state.redirectForgotpass) {
      return (<Redirect to={'/reset password'} />)
    }

    if (this.state.redirectAdmin) {
      return (<Redirect to={'/AdminDashboard'} />)
    }

    if (this.state.redirectResercher) {
      return (<Redirect to={{
        pathname: '/ResercherDashboard',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/My Dashboard',
      }} />)
    }

    if (this.state.redirectPClientDashboard) {
      return (<Redirect to={{
        pathname: '/PClientViewKolList',
      }} />)
    }

    if (this.state.redirectClientViewKolList) {
      return (<Redirect to={{
        pathname: '/My Database',
      }} />)
    }

    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }

    
    return (


      <body id="landing-body" style={{ backgroundColor: '#ccc' }}>


        <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}

        {/* Call the function to add a library */}
        {/* {AddLibrary('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')} */}
        {/* 
  {AddLibrary('./js/jquery.min.js')}
   {AddLibrary('./js/bootstrap.bundle.min.js')}
  {AddLibrary('./js/owl.carousel.min.js')} */}
        {AddLibrary('./js/app.js')}



        <div style={{ backgroundColor: '#e9ecef', marginTop: '0px', paddingTop: '40px' }}>

          <LoginHeader />

          <section id="services" className="text-center" >
            <div className="container" >
              <div className="row">
                <div className="col-12">
                  <div className="login" style={{ padding: '2%' }}>
                    <div>
                      <h4>Welcome Back !</h4>
                      <p>Sign in to continue to Cetas Healthcare.</p>
                    </div>
                    <div className='inputbox'>
                      <label for="" style={{ marginBottom: '10px' }} >Email ID</label>
                      <Input id="email_id" placeholder="Email ID " tabindex='1' class="input"
                        type="email"
                        value={this.state.loginData.email_id} onChange={(e) => {
                          let { loginData } = this.state;
                          loginData.email_id = e.target.value;
                          this.setState({ loginData });
                          // this.setState({ loginData, teamLoginIdForTerms: e.target.value });
                        }}
                      />
                    </div>
                    <div className="error"> {this.state.UseremailErr}</div>

                    <div className='inputbox'>
                      <label for="" style={{ width: '50%', marginBottom: '10px' }}  >Password</label>
                      <forgotpass style={{ width: '50%', marginBottom: '10px' }}><a href='#' onClick={this.redirectForgotpass.bind(this)} style={{ color: '#9ea9ad', fontWeight: 'normal' }}>Forgot password?</a></forgotpass>
                      <Input id="password" placeholder="Password" class="input" tabindex='2'
                        type="password"
                        value={this.state.loginData.password} onChange={(e) => {
                          let { loginData } = this.state;
                          loginData.password = e.target.value;
                          this.setState({ loginData });
                        }}
                      />

                    </div>
                    <div className="error"> {this.state.PasswordErr}</div>


                    <div >
                      <button type="submit" tabindex='3' onClick={this.login_user.bind(this)}>
                        Sign In
                      </button>
                    </div>
                    <div style={{ visibility: 'hidden', height: '2px' }}>
                      <h5>Sign In with</h5>
                      <div className='inputboxsocial'>
                        <a href="#" target="_blank"><i className='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i className='bx bxl-google'></i></a>
                        <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>

                        <a href="#" target="_blank"><i className='bx bxl-twitter'></i></a>

                      </div>
                    </div>
                    {/* </form>
                            </div> */}
                  </div>
                </div>
              </div>
              <div className="signuplink">
                <p>Don't have an account ?
                  <a href='#' onClick={this.redirectNewSignup.bind(this)} >Signup</a>
                  {/* <a href='#' onClick={ this.redirectsetpassword.bind(this)} >Setpassword</a> */}
                </p>
              </div>
            </div>
          </section>

          <Pagefooter />

        </div>




        <Modal isOpen={this.state.loginModal} toggle={this.toggleLoginModal.bind(this)} centered>
          <ModalHeader toggle={this.toggleLoginModal.bind(this)}
            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
            style={{ borderBottom: '0px', height: '10px' }}>
            {/* <h1 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Login</h1>
              <div style={{ justifyContent: 'center', marginTop: '30px' }}>
              <img id="lglogos" src={require('./Assets/Images/cetas_healthcare_logo.svg')} alt="Cetas"></img>
    
              </div> */}
          </ModalHeader>


        </Modal>






        <div className="modal fade" id="exampleModallogin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body p-0">
                <div className="container-fluid">
                  <div className="row gy-12">
                    <div className="col-lg-12">
                      <form className="p-lg-5 col-12 row g-3">
                        <div>
                          <img id="lglogos" src={require('../Assets/Images/cetas_healthcare_logo.svg')} alt="Cetas"></img>
                        </div>
                        <div className="col-lg-12">
                          <label for="" className="form-label"></label>
                          {/* <input type="text" className="form-control" placeholder="Enter Username" id="" aria-describedby=""></input> */}
                          <Input id="Client_Id" placeholder="Team Id"
                            style={{ width: '80%', height: '50px', }}
                            type="email"
                            value={this.state.loginData.client_id} onChange={(e) => {
                              let { loginData } = this.state;
                              loginData.client_id = e.target.value;
                              this.setState({ loginData, teamLoginIdForTerms: e.target.value });
                            }}
                          />
                        </div>

                        <div className="centerErrLogin">{this.state.UseremailErr}</div>

                        <div className="col-lg-12">
                          {/* <input type="password" className="form-control" placeholder="Enter Password" id="" aria-describedby=""></input> */}
                          <Input id="password" placeholder="Password"
                            style={{ width: '80%', height: '40px', }}
                            type="password"
                            value={this.state.loginData.password} onChange={(e) => {
                              let { loginData } = this.state;
                              loginData.password = e.target.value;
                              this.setState({ loginData });
                            }}
                          />
                        </div>
                        <div className="centerErrLoginPass">{this.state.PasswordErr}</div>

                        <div className="col-12">
                          {/* <select className="form-control">
                                            <option>--Select Role--</option>
                                            <option>Admin</option>
                                            <option>Client</option>
                                            <option>Employee</option>
                                            <option>Researcher</option>
                                            <option>Project Manager</option>
                                        </select> */}
                          <Input type="select" id="roleSelect"
                            style={{ width: '80%', height: '40px', marginTop: '3%' }}
                            value={this.state.loginData.role}
                            // value2={this.state.newNewsData.category_name}
                            onChange={(e) => {
                              let { loginData } = this.state;
                              loginData.role = e.target.value;
                              console.log("employee role", e.target.value);
                              // newNewsData.category_name = e.target.value2;
                              // console.log(e.target.value2);
                              this.setState({ loginData });
                            }}>
                            <option value="0" >--Select Role--</option>
                            <option value="admin" >Admin</option>
                            <option value="researcher" >Researcher</option>
                            <option value="project manager" >Project Manager</option>
                            <option value="employee" >Employee</option>
                            {/* {CategoryItems}    */}
                          </Input>
                        </div>
                        <div className="centerErrLogin" >{this.state.SelectRoleErr}</div>

                      </form>


                      <div className="col-12">
                        {/* <button type="submit" className="btn btn-brand btn-block w-100">Login</button> */}
                        {/* <button type="submit" class="cetas-button" style={{ marginRight: '20%', marginTop: '-15px' }}
                                onClick={this.login.bind(this)}>
                                Login
                            </button> */}
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>



        {/* </header> */}

      </body>
    );
  }
}

export default Login;