import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingBar from "react-top-loading-bar";
import Select from 'react-select';
import Swal from 'sweetalert2';
import { Tabs, Tab } from 'react-bootstrap';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import AdminHeader from './AdminHeader';

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);

function statusFormatter(cell, row) {
    // console.log("in status formatter")
    console.log("row", row);
    console.log("cell", cell);
    // if (row.project_status) {
    // return (
    //   <span>
    //     <strong style={ { color: 'red' } }>{cell}</strong>
    //   </span>
    //     );
    // }
    if (cell == "Ongoing") {
        return (
            <span>
                <strong style={{ color: '#FF7F41' ,fontSize:'15px' }}>{cell}</strong>
            </span>
        );
    }

    if (cell == "Upcoming") {
        return (
            <span>
                <strong style={{ color: '#41A2FF' ,fontSize:'15px' }}>{cell}</strong>
            </span>
        );
    }

    if (cell == "Completed") {
        return (
            <span>
                <strong style={{ color: '#1FCE39' ,fontSize:'15px' }}>{cell}</strong>
            </span>
        );
    }

    return (
        <span>{cell}</span>
    );
}



function CredentialstatusFormatter(cell, row) {
    // console.log("in status formatter")
    console.log("row", row);
    console.log("cell", cell);
    
    if (cell == "Created") {
        return (
            <span>
                <strong style={{ color: '#1FCE39' ,fontSize:'15px' , fontWeight:'normal' }}>{cell}</strong>
            </span>
        );
    }

    if (cell == "Not created") {
        return (
            <span>
                <strong style={{ color: 'red' ,fontSize:'15px', fontWeight:'normal' }}>{cell}</strong>
            </span>
        );
    }

    
    return (
        <span>{cell}</span>
    );
}


const customStyles = {
    control: base => ({
        ...base,
        height: 50,
        width: 280,
        // minHeight: 35
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: 50,
        width: 280,
        align: 'center',
        padding: '-1px'
    }),
    // for list of options
    // option: (provided, state) => ({
    //     ...provided,
    //     padding: 20,
    // }),
};


class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token:'',
            login_type:'',
            ShowBar: false,
            redirect: false,
            redirectCreateProject: false,
            redirectEditProject: false,
            redirectFormManagement: false,
            redirectManageKolProfile: false,
            redirectfinancialreport:false,
            redirectManageSignupProfile: false,
            redirectPreClientManagement:false,
            redirectSpecialityCurd: false,
            redirectSubSpecialityCurd: false,
            redirectProcedureTypeCurd: false,

            redirectConditionTypeCurd:false,
            redirectCountryListCurd:false,
            redirectStateListCurd:false,
            

            RedirectRatingFlag: false,
            RedirectParamConfigFlag: false,
            idForCreateNewProject: '',
            projectInfo: [],
            employeeInfo: [],
            ClientCredList: [],
            KOLCredList: [],

            // search and select name
            selectedOptionClientCredentials: '',
            selectedOptionKolCredentials: '',


            ProjectList: [],
            //isEdit: false,
            project_id: '',
            p_name: '',
            threauptic_area: '',
            market: '',
            project_scope: '',
            start_date: '',
            end_date: '',
            project_plan: '',
            project_proposal: '',
            project_sow: '',
            event_name: '',
            engagement_type: '',
            procedure_type: '',
            speciality_type: '',
            no_of_participants: '',
            project_manager: '',
            team_members: '',
            submitted_by: '',
            shortlisted_respondents: '',
            finalist_respondents: '',
            project_status: '',
            comment: '',
            project_docs: '',
            last_updated_on: '',

            onStatusfilters: null,

            ViewProjectDetailsModal: false,

            // To set values from api
            ProjectName: '',
            CompanyName: '',
            ClientName: '',
            RequiredKOLProfile: '',
            ProjectScope: '',
            Market: '',
            StartDate: '',
            EndDate: '',
            ProjectPlan: '',
            ProjectPraposal: '',
            ProjectSow: '',
            EventName: '',
            EngagementType: '',
            ProcedureType: '',
            SubSpecialityType: '',
            Speciality: '',
            NoOfParticipants: '',
            ProjectManager: '',
            TeamMembers: '',
            SubmitedBy: '',
            ShortlistedRespondents: '',
            FinalistdRespondents: '',
            UpdatedProjectStatus: '',
            Comments: '',
            ProjectDocuments: '',

            // create credentials variables
            projectIdForCredential: '',
            CraeteCrendialsModal: false,
            CreateClientCredentials: {
                login_id: '',
                password: '',
            },
            CreateKOLCredentials: {
                login_id: '',
                password: '',
            },
            // error for create creadentials
            PasswordErr: '',
            clientNameErr: '',

            KOLPasswordErr: '',
            KOLclientNameErr: '',


            columns: [
                {
                    dataField: 'p_name',
                    text: 'Project',
                    filter: textFilter(),
                    formatter: this.link,
                    filterRenderer: (onFilter, column) => {
                        this.onProjectNameFilter = onFilter;
                        return null;
                    },
                    // align: 'center',
                    // headerStyle: (colum, colIndex) => {
                    // return { textAlign: 'center' };
                    // },
                },
                {
                    dataField: 'project_manager',
                    text: 'Project Manager',
                    // align: 'center',
                    // headerStyle: (colum, colIndex) => {
                    // return { textAlign: 'center' };
                    // },
                },
                {
                    dataField: 'client_name',
                    text: 'Client Name',
                    // align: 'center',
                    // headerStyle: (colum, colIndex) => {
                    // return { textAlign: 'center' };
                    // },
                },
                {
                    dataField: 'project_status',
                    text: 'Status',
                    formatter: statusFormatter,
                    style: (cell, row, rowIndex, colIndex) => {
                        return { fontSize: '15px', fontWeight: 'lighter' };
                    },
                    filter: customFilter({
                        type: FILTER_TYPES.TEXT,
                        comparator: Comparator.LIKE,// default is Comparator.LIKE
                    }),
                    filterRenderer: (onFilter, column) => {
                        this.onStatusfilters = onFilter;
                        return null;
                    },
                    // align: 'center',
                    // headerStyle: (colum, colIndex) => {
                    // return { textAlign: 'center' };
                    // },
                },
                {
                    dataField: 'credentials',
                    text: 'Credential Status',
                    formatter:CredentialstatusFormatter,
                    // style: (cell, row, rowIndex, colIndex) => {
                    //     if (row.credentials == "Created") {
                    //         return {
                    //             color: '#1FCE39'
                    //         };
                    //     } else if (row.credentials == "Not Created") {
                    //         return {
                    //             color: 'red'
                    //         };
                    //     }
                    // }
                },
                // {
                //     dataField: "",
                //     text: "Action",
                //     formatter: this.linkFollow,
                // },
                // {
                //     //dataField: "follow",
                //     text: "Edit",
                //     formatter: this.linkEdit,
                //     align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //     return { textAlign: 'center' };
                //     },
                // },

            ]
        };
    }

    // history.pushState(null, null, location.href);
    //     window.onpopstate = function () {
    //         history.go(1);
    //     };

    componentDidMount = async () => {

        let getSession_token = await sessionStorage.getItem('token')
       // console.log('to',getSession_token);
        if (getSession_token !== null) {
          await this.setState({
            token: getSession_token,
          })
        }
        else
        { 
            this.setState({redirect: true})
        }

        let getSession_login_type = await sessionStorage.getItem('login_type')
        //console.log('l_type',getSession_login_type);
        if (getSession_login_type !== null) {
          await this.setState({
            login_type: getSession_login_type,
          })
        }
        else
        { 
            this.setState({redirect: true})
        }

        this.setState({
            ShowBar: true,
        })

        const options = {
            method: 'POST',
        }

        fetch(global.websiteUrl+'/service/User/get_DashboardCount', options)
            .then((Response) => Response.json())
            //   .then((Response) => Response.text())
            .then((findresponse) => {
                console.log(findresponse)
                var testData = findresponse.Project_Info;
                var testData2 = findresponse.Employee_Info;
                console.log("testData", JSON.stringify(testData));
                console.log("testData", JSON.stringify(testData2));
                this.setState({
                    projectInfo: findresponse.Project_Info,
                    employeeInfo: findresponse.Employee_Info,
                })
            })


        fetch(global.websiteUrl+'/service/Project/get_NewProjectList', options)
            .then((Response) => Response.json())
            //   .then((Response) => Response.text())
            .then((findresponse) => {
                console.log(findresponse)
                var PList = findresponse.Project_List;
                var newProjId = findresponse.pro_gen_id;
                console.log("newProjId", newProjId)
                console.log("PList", JSON.stringify(PList));
                this.setState({
                    ProjectList: findresponse.Project_List,
                    idForCreateNewProject: newProjId,
                })
                if (findresponse.status == false) {
                    this.setState({
                        ProjectList: [],
                    })
                }
                this.setState({
                    ShowBar: false,
                })
            })

        fetch(global.websiteUrl+'/service/Client/Get_Client_Credentials_List', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
               // var testData = findresponse.Client_list;
                //console.log("testDataClientCredList=====", JSON.stringify(testData));
                //this.setState({
                  //  ClientCredList: findresponse.Client_list,
               // })

               if (findresponse.statusCode === 400) {
                // Swal.fire({
                  //   icon: 'error',
                    // text: findresponse.message,
                     //confirmButtonColor: '#d33',
                     //cancelButtonColor: '#d33',
                     //confirmButtonText: 'OK'
                // })

             } else {
                var testData = findresponse.Client_list;
                console.log("testDataClientCredList=====", JSON.stringify(testData));
                this.setState({
                  ClientCredList: findresponse.Client_list,
                })
             }


            })

        fetch(global.websiteUrl+'/service/KOL_Dashboard/Get_KOL_Credentials_List', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                if (findresponse.statusCode === 400) {
                   // Swal.fire({
                     //   icon: 'error',
                       // text: findresponse.message,
                        //confirmButtonColor: '#d33',
                        //cancelButtonColor: '#d33',
                        //confirmButtonText: 'OK'
                   // })

                } else {
                    var testData = findresponse.Client_list;
                    console.log("testDataKOLCredList=====", JSON.stringify(testData));
                    this.setState({
                        KOLCredList: findresponse.Client_list,
                    })
                }
            })

    }

    empNav() {
        this.setState({ redirect: true })
    }

    createProjectNav() {
        this.setState({ redirectCreateProject: true })
    }

    formManagementNav() {
        this.setState({ redirectFormManagement: true })
    }

    manageKolProfileNav() {
        this.setState({ redirectManageKolProfile: true })
    }

    financialreportNav(){

        this.setState({ redirectfinancialreport: true })
    }

    manageSignupProfileNav() {
        this.setState({ redirectManageSignupProfile: true })
    }

    
    managePreClientProfileNav() {
        this.setState({ redirectPreClientManagement: true })
    }
    

    SpecialityNav() {
        this.setState({ redirectSpecialityCurd: true })
    }

    SubSpecialityNav() {
        this.setState({ redirectSubSpecialityCurd: true })
    }

    ProcedureTypeNav() {
        this.setState({ redirectProcedureTypeCurd: true })
    }


    ConditionTypeNav() {
        this.setState({ redirectConditionTypeCurd: true })
    }
    CountryListNav() {
        this.setState({ redirectCountryListCurd: true })
    }

    StateListNav() {
        this.setState({ redirectStateListCurd: true })
    }




    //Funtion to apply links to project names
    link = (cell, row) => {
        console.log("check", row.project_id)
        var project_id = row.project_id;
        var p_name = row.p_name;
        return (
            <div>
                <a style={{  color: '#069', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={this.GetProjectDetails.bind(this, project_id)}> {row.p_name} </a>
            </div>
        )
    }

    GoToRating() {
        // console.log('project_name Here', p_name);
        this.setState({ RedirectRatingFlag: true })
    }

    GoToParamConfiguration() {
        console.log('GoToParamConfiguration is clicked');
        this.setState({ RedirectParamConfigFlag: true })
    }

    GetProjectDetails = (project_id) => {
        // console.log('Id Here', project_id);
        { this.fetchProjectDetails(project_id) }
        { this.toggleViewProjectDetailsModal() }
    }

    // View ProjectDetails Modal Functions
    toggleViewProjectDetailsModal = () => {
        this.setState({
            ViewProjectDetailsModal: !this.state.ViewProjectDetailsModal,
        });
    }

    // to fetch project details
    fetchProjectDetails = (project_id) => {
        console.log("in fetch project details", project_id)

        const url = global.websiteUrl+'/service/Project/Fetch_Project_List';
        let formData = new FormData();
        formData.append('project_id', project_id);

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var projectDetails = response.data.Project_List;
                console.log("projectDetails", projectDetails);
                console.log("projectDetails =-=-=", JSON.stringify(projectDetails));

                projectDetails.map(async (data) => {
                    await this.setState({
                        ProjectName: data.p_name,
                        CompanyName: data.company_nm,
                        ClientName: data.client_unique_id,
                        RequiredKOLProfile: data.required_kol_profile,
                        ProjectScope: data.project_scope,
                        Market: data.market,
                        StartDate: data.start_date,
                        EndDate: data.end_date,
                        ProjectPlan: data.project_plan,
                        ProjectPraposal: data.project_proposal,
                        ProjectSow: data.project_sow,
                        EventName: data.event_name,
                        EngagementType: data.engagement_type,
                        ProcedureType: data.procedure_type_id,
                        SubSpecialityType: data.sub_speciality_id,
                        Speciality: data.speciality_id,
                        NoOfParticipants: data.no_of_participant,
                        ProjectManager: data.project_manager_id,
                        TeamMembers: data.team_members_id,
                        ShortlistedRespondents: data.shortlisted_respondents,
                        FinalistdRespondents: data.finalist_respondents,
                        // UpdatedProjectStatus: data.project_status,
                        Comments: data.comments,
                        ProjectDocuments: data.project_documents_upload,

                        // selectedOptionCompany: data.company_nm,
                        selectedOptionClient: data.client_unique_id,
                    })
                })

                // To get client name from id
                if (this.state.ClientName !== '') {
                    const url = global.websiteUrl+'/service/Client/get_ClientList';
                    let formData = new FormData();
                    formData.append('company_nm', this.state.CompanyName);
                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var clientListData = response.data.Employee_Details;
                            console.log("clientListData", JSON.stringify(clientListData));

                            if (clientListData) {
                                clientListData.forEach(element => {
                                    if (element.client_id == this.state.ClientName) {
                                        // console.log("same option is here",element);
                                        this.setState({
                                            ClientName: element.clientname,
                                        })
                                    }
                                })
                            }
                        })
                }

                // to get market names from ids
                if (this.state.Market !== '') {
                    const options = {
                        method: 'POST',
                    }
                    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
                        .then((Response) => Response.json())
                        .then((findresponse) => {
                            console.log(findresponse)
                            var testDataCountryList = findresponse.Country_Details;
                            console.log("testDataCountryList", JSON.stringify(testDataCountryList));

                            var Market3 = [];
                            var Market2 = this.state.Market.split(',');
                            console.log('Market2', Market2)
                            testDataCountryList.forEach(element => {
                                Market2.forEach(elementMarket => {
                                    if (element.id == elementMarket) {
                                        console.log("same option is here Market", element);
                                        var joined = Market3.concat(element.country_name);
                                        Market3 = joined;
                                        console.log("Market3", Market3);
                                    }
                                })
                            })
                            this.setState({
                                Market: Market3.toString(),
                            })
                        })
                }

                //to get speciality name from id
                if (this.state.Speciality) {
                    const options = {
                        method: 'POST',
                    }
                    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
                        .then((Response) => Response.json())
                        .then((findresponse) => {
                            console.log(findresponse)
                            var testDataSpeciality = findresponse.data;
                            console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));

                            if (testDataSpeciality) {
                                testDataSpeciality.forEach(element => {
                                    if (element.id == this.state.Speciality) {
                                        // console.log("same option is here",element);
                                        this.setState({
                                            Speciality: element.speciality_type,
                                        })
                                    }
                                })
                            }
                        })
                    // to get sub speciality type name from id
                    if (this.state.SubSpecialityType) {
                        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                        let formData = new FormData();
                        formData.append('speciality_type_id', this.state.Speciality);
                        formData.append('search_type', "Sub Speciality");
                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var subSpecialitydata = response.data.data;
                                console.log("subSpecialitydata", JSON.stringify(subSpecialitydata));

                                if (subSpecialitydata) {
                                    subSpecialitydata.forEach(element => {
                                        if (element.id == this.state.SubSpecialityType) {
                                            // console.log("same option is here",element);
                                            this.setState({
                                                SubSpecialityType: element.sub_speciality_type,
                                            })
                                        }
                                    })
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                    // //to get procedure type name from id
                    if (this.state.ProcedureType) {
                        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                        let formData = new FormData();
                        formData.append('speciality_type_id', this.state.Speciality);
                        formData.append('search_type', "Procedure");

                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var proceduredata = response.data.data;
                                console.log("proceduredata", JSON.stringify(proceduredata));

                                if (proceduredata) {
                                    proceduredata.forEach(element => {
                                        if (element.id == this.state.ProcedureType) {
                                            // console.log("same option is here",element);
                                            this.setState({
                                                ProcedureType: element.procedure_type,
                                            })
                                        }
                                    })
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }

                // to get project manager name from id
                if (this.state.ProjectManager) {
                    const options = {
                        method: 'POST',
                    }
                    fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
                        .then((Response) => Response.json())
                        .then((findresponse) => {
                            console.log(findresponse)
                            var testDataEmployeeList = findresponse.Employee_Details;
                            console.log("testDataEmployeeList", JSON.stringify(testDataEmployeeList));
                            if (testDataEmployeeList) {
                                testDataEmployeeList.forEach(element => {
                                    if (element.emp_id == this.state.ProjectManager) {
                                        this.setState({
                                            ProjectManager: element.emp_name,
                                        })
                                    }
                                })
                            }
                        })
                }

                // to get team members from id
                if (this.state.TeamMembers) {
                    const options = {
                        method: 'POST',
                    }
                    fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
                        .then((Response) => Response.json())
                        .then((findresponse) => {
                            console.log(findresponse)
                            var testDataEmployeeList = findresponse.Employee_Details;
                            console.log("testDataEmployeeList", JSON.stringify(testDataEmployeeList));

                            var TeamMembers3 = [];
                            var TeamMembers2 = this.state.TeamMembers.split(',');
                            console.log('TeamMembers2', TeamMembers2)
                            testDataEmployeeList.forEach(element => {
                                TeamMembers2.forEach(elementTeamMembers => {
                                    if (element.emp_id == elementTeamMembers) {
                                        console.log("same option is here Team Members", element);
                                        var joined = TeamMembers3.concat(element.emp_name);
                                        TeamMembers3 = joined;
                                        console.log("TeamMembers3", TeamMembers3);
                                    }
                                })
                            })
                            this.setState({
                                TeamMembers: TeamMembers3.toString(),
                            })

                        })
                }

            })


    }

    linkEdit = (cell, row, rowIndex, formatExtraData) => {
        // console.log(row.event_name)
        return (
            <div>
                <Button color="danger" size="sm" className="mr-2"
                    onClick={this.edit.bind(this, row.project_id, row.p_name, row.threauptic_area, row.market, row.project_scope,
                        row.start_date, row.end_date, row.project_plan, row.project_proposal, row.project_sow,
                        row.event_name, row.engagement_type, row.procedure_type, row.speciality_type, row.no_of_participants,
                        row.project_manager, row.team_members, row.submitted_by, row.shortlisted_respondents,
                        row.finalist_respondents, row.project_status, row.comment, row.project_docs,
                        row.last_updated_on)}>
                    Edit
                </Button>
            </div>
        );
    };

    edit(project_id, p_name, threauptic_area, market, project_scope, start_date, end_date, project_plan, project_proposal,
        project_sow, event_name, engagement_type, procedure_type, speciality_type, no_of_participants, project_manager,
        team_members, submitted_by, shortlisted_respondents, finalist_respondents, project_status, comment,
        project_docs, last_updated_on) {
        this.setState({
            redirectEditProject: true,
            project_id: project_id, p_name: p_name, threauptic_area: threauptic_area, market: market, project_scope: project_scope,
            start_date: start_date, end_date: end_date, project_plan: project_plan, project_proposal: project_proposal,
            project_sow: project_sow, event_name: event_name, engagement_type: engagement_type,
            procedure_type: procedure_type, speciality_type: speciality_type, no_of_participants: no_of_participants,
            project_manager: project_manager, team_members: team_members, submitted_by: submitted_by,
            shortlisted_respondents: shortlisted_respondents, finalist_respondents: finalist_respondents,
            project_status: project_status, comment: comment, project_docs: project_docs, last_updated_on: last_updated_on,
        });
    }

    toggleEditModal() {
        this.setState({
            isEdit: !this.state.isEdit
        });
    }

    onStatusFilter = (event) => {
        const { value } = event.target;
        this.onStatusfilters(value);
    };

    onProjectNameChange = (event) => {
        const { value } = event.target;
        this.onProjectNameFilter(value);
    };

    // create client creadential funs
    // linkFollow = (cell, row, rowIndex, formatExtraData) => {
    //     var projectIdForCredential = row.project_id;
    //     return (
    //         <div>
    //             {console.log("===row.status" ,row.project_status)}
    //             {
    //                 (row.project_status == "Completed")?(
    //                     <button  className="cetas-button cetas-button-small mr-2"
    //                      onClick={this.toggleCraeteCrendialsModal.bind(this,projectIdForCredential )}>
    //                         Create Credentials
    //                     </button>
    //                 ):(
    //                     <div></div>
    //                 )
    //             }
    //         </div>
    //         );
    // }

    toggleCraeteCrendialsModal() {
        this.setState({
            // projectIdForCredential: projectIdForCredential,
            CraeteCrendialsModal: !this.state.CraeteCrendialsModal,
            clientNameErr: '',
            PasswordErr: '',
            KOLPasswordErr: '',
            KOLclientNameErr: '',
            selectedOptionClientCredentials: '',
            selectedOptionKolCredentials: '',
            CreateClientCredentials: {
                login_id: '',
                password: '',
            },
            CreateKOLCredentials: {
                login_id: '',
                password: '',
            },

        });
    }

    validateCreateClientCredentials() {
        let clientNameErr = '';
        let PasswordErr = '';

        if (!this.state.CreateClientCredentials.login_id) {
            clientNameErr = 'Please Select Client Name';
        }

        if (!this.state.CreateClientCredentials.password) {
            PasswordErr = 'Please Enter Password';
        }

        if (PasswordErr || clientNameErr) {
            this.setState({ PasswordErr, clientNameErr });
            return false;
        }
        return true;
    }

    handleChangeClintName = async (selectedOptionClientCredentials) => {

        this.setState({ selectedOptionClientCredentials });
        console.log(`Option selected...........:`, selectedOptionClientCredentials);

        let Client_name = selectedOptionClientCredentials.value;
        let { CreateClientCredentials } = this.state;
        CreateClientCredentials.login_id = Client_name;
        await this.setState({ CreateClientCredentials });

    };

    handleChangeKolName = async (selectedOptionKolCredentials) => {

        this.setState({ selectedOptionKolCredentials });
        console.log(`Option selected...........:`, selectedOptionKolCredentials);

        let Kol_name = selectedOptionKolCredentials.value;

        let { CreateKOLCredentials } = this.state;
        CreateKOLCredentials.login_id = Kol_name;
        await this.setState({ CreateKOLCredentials });

    };

    CreateClientCrendialsFun() {
        console.log("CreateClientCredentials====", this.state.CreateClientCredentials)
        console.log("projectIdForCredential====", this.state.projectIdForCredential)
        const isValid = this.validateCreateClientCredentials();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            const url = global.websiteUrl+'/service/Client/Create_Client_Credentials';
            let formData = new FormData();
            formData.append('client_id', this.state.CreateClientCredentials.login_id);
            formData.append('username', this.state.CreateClientCredentials.login_id);
            formData.append('password', this.state.CreateClientCredentials.password);
            formData.append('credentials', 'Created');

            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var lgdata = response.data;
                    console.log("lgdata", JSON.stringify(lgdata));
                    var lgstatus = response.data.status;
                    console.log("lgstatus", JSON.stringify(lgstatus));
                    console.log("response message2", response.data.message)
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (lgstatus == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    else if (lgstatus == false) {
                        // alert("Wrong Username Or Password")
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Wrong Username or Password!',
                            // showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.refreshClientCredList();
                })
                .catch(error => {
                    console.log(error);
                });

            this.setState({
                CraeteCrendialsModal: false,
                selectedOptionClientCredentials: '',
                selectedOptionKolCredentials: '',
                CreateClientCredentials: {
                    login_id: '',
                    password: '',
                },
            });

        }
    }

    refreshClientCredList() {
        const options = {
            method: 'POST',
        }
        fetch(global.websiteUrl+'/service/Client/Get_Client_Credentials_List', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var testData = findresponse.Client_list;
                console.log("testDataClientCredList=====", JSON.stringify(testData));
                this.setState({
                    ClientCredList: findresponse.Client_list,
                })
            })
    }

    validateKolCreateCredentials() {
        let KOLPasswordErr = '';
        let KOLclientNameErr = '';

        if (!this.state.CreateKOLCredentials.login_id) {
            KOLclientNameErr = 'Please Enter Password';
        }

        if (!this.state.CreateKOLCredentials.password) {
            KOLPasswordErr = 'Please Enter Password';
        }

        if (KOLPasswordErr || KOLclientNameErr) {
            this.setState({ KOLPasswordErr, KOLclientNameErr });
            return false;
        }
        return true;
    }

    CreateKolCrendialsFun() {
        console.log("CreateKOLCredentials====", this.state.CreateKOLCredentials)
        const isValid = this.validateKolCreateCredentials();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            const url = global.websiteUrl+'/service/KOL_Dashboard/Create_KOL_Credentials';
            let formData = new FormData();
            formData.append('kol_id', this.state.CreateKOLCredentials.login_id);
            formData.append('password', this.state.CreateKOLCredentials.password);
            formData.append('credentials', 'Created');

            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var lgdata = response.data;
                    console.log("lgdata", JSON.stringify(lgdata));
                    var lgstatus = response.data.status;
                    console.log("lgstatus", JSON.stringify(lgstatus));
                    console.log("response message2", response.data.message)
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (lgstatus == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    else if (lgstatus == false) {
                        // alert("Wrong Username Or Password")
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Wrong Username or Password!',
                            // showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.refreshKOLCredList();
                })
                .catch(error => {
                    console.log(error);
                });

            this.setState({
                CraeteCrendialsModal: false,
                selectedOptionClientCredentials: '',
                selectedOptionKolCredentials: '',
                CreateKOLCredentials: {
                    login_id: '',
                    password: '',
                },
            });
        }
    }

    refreshKOLCredList() {
        const options = {
            method: 'POST',
        }
        fetch(global.websiteUrl+'/service/KOL_Dashboard/Get_KOL_Credentials_List', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var testData = findresponse.Client_list;
                console.log("testDataKOLCredList=====", JSON.stringify(testData));
                this.setState({
                    KOLCredList: findresponse.Client_list,
                })
            })
    }


    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/EmployeeManagement'} />)
        }

        if (this.state.redirectSpecialityCurd) {
            return (<Redirect to={'/SpecialityCurd'} />)
        }

        if (this.state.redirectSubSpecialityCurd) {
            return (<Redirect to={'/SubSpecialityCurd'} />)
        }

        if (this.state.redirectProcedureTypeCurd) {
            return (<Redirect to={'/ProcedureTypeCurd'} />)
        }

        if (this.state.redirectConditionTypeCurd) {
            return (<Redirect to={'/ConditionCurd'} />)
        }

        if (this.state.redirectCountryListCurd) {
            return (<Redirect to={'/CountryCurd'} />)
        }

        if (this.state.redirectStateListCurd) {
            return (<Redirect to={'/StateCurd'} />)
        }

        if (this.state.RedirectRatingFlag) {
            return (<Redirect to={'/Rating'} />)
        }

        if (this.state.RedirectParamConfigFlag){
            return (<Redirect to={'/ParameterConfiguration'} />)
        }

        if (this.state.RedirectAdminProjectDetailsFlag) {
            return (<Redirect to={{
                pathname: '/AdminProjectDetails',
                state: {
                    project_id: this.state.project_id,
                    p_name: this.state.p_name,
                }
            }} />)
        }

        if (this.state.redirectCreateProject) {
            return (<Redirect to={{
                pathname: '/CreateProject',
                state: { idForCreateNewProject: this.state.idForCreateNewProject }
            }} />)
        }

        let ClientCredList = this.state.ClientCredList;
        let OptionsClientCredList = ClientCredList.map((ClientCredList) =>
            <option key={ClientCredList.client_id} value={ClientCredList.client_id}>
                {ClientCredList.client_name}</option>
        );

        let KOLCredList = this.state.KOLCredList;
        let OptionsKOLCredList = KOLCredList.map((KOLCredList) =>
            <option key={KOLCredList.kol_unique_id} value={KOLCredList.kol_unique_id}>
                {KOLCredList.name}</option>
        );

        if (this.state.redirectEditProject) {
            return (<Redirect to={{
                pathname: '/EditProject',
                state: {
                    project_id: this.state.project_id, p_name: this.state.p_name, threauptic_area: this.state.threauptic_area,
                    market: this.state.market, project_scope: this.state.project_scope, start_date: this.state.start_date,
                    end_date: this.state.end_date, project_plan: this.state.project_plan,
                    project_proposal: this.state.project_proposal, project_sow: this.state.project_sow,
                    event_name: this.state.event_name, engagement_type: this.state.engagement_type,
                    procedure_type: this.state.procedure_type, speciality_type: this.state.speciality_type,
                    no_of_participants: this.state.no_of_participants, project_manager: this.state.project_manager,
                    team_members: this.state.team_members, submitted_by: this.state.submitted_by,
                    shortlisted_respondents: this.state.shortlisted_respondents,
                    finalist_respondents: this.state.finalist_respondents, project_status: this.state.project_status,
                    comment: this.state.comment, project_docs: this.state.project_docs,
                    last_updated_on: this.state.last_updated_on,
                }
            }}
            />)
        }
        if (this.state.redirectFormManagement) {
            return (<Redirect to={'/FormManagement'} />)
        }
        if (this.state.redirectManageKolProfile) {
            return (<Redirect to={'/ManageKolProfile'} />)
        }

        if (this.state.redirectfinancialreport) {
            return (<Redirect to={'/financialreport'} />)
        }

        
        if (this.state.redirectManageSignupProfile) {
            return (<Redirect to={'/ClientSignupProfile'} />)
        }

        if (this.state.redirectPreClientManagement) {
            return (<Redirect to={'/PreClientManagement'} />)
        }

        

        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            return (
                <Button outline color="danger" className="mr-2" onClick={handleClick} > {page} </Button>
            );
        };

        const options = {
            pageButtonRenderer,
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }, {
                text: 'All', value: this.state.ProjectList.length
            }] // A numeric array is also available. the purpose of above example is custom the text
        };

        return (
            <body style={{marginTop:'15vh'}}>
                {/* <div class="wrapper">
    <div class="container-fluid">
    <div class="row"> */}
                <div>
                    {this.state.ShowBar ? <div style={{ position: 'fixed', top: '0' }}>
                        <LoadingBar
                            progress='100'
                            // onLoaderFinished={() => setProgress(0)}
                            //  progress={progress}
                            height={3} color="#A90007" transitionTime={4000}
                            loaderSpeed={4000} />
                    </div>
                        : null}
                </div>
                {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                    <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                </Modal> : null}
                <div>
                    <div>
                        <div>
                            <AdminHeader />
                            <div className="container">
                                <div className="content-wrapper">
                                    <div className="screen-headingbox">
                                        <h2>Admin Dashboard
                                            {/* <button className="cetas-button float-right" 
                        onClick={this.formManagementNav.bind(this)}>Form Management</button> */}
                                            <button className="cetas-button float-right"
                                                onClick={this.GoToParamConfiguration.bind(this)}>Configuration</button>
                                            <button className="cetas-button float-right"
                                                onClick={this.toggleCraeteCrendialsModal.bind(this)}>Create New Credentials</button>
                                            <button className="cetas-button float-right"
                                                onClick={this.manageKolProfileNav.bind(this)}>Manage KOL Profiles</button>
                                                <button className="cetas-button float-right"
                                                onClick={this.financialreportNav.bind(this)}>Financial Report</button>
                                           
                                            {/* <button className="cetas-button float-right"
                                                onClick={this.manageSignupProfileNav.bind(this)}>Manage Signup</button>
                                                 <button className="cetas-button float-right"
                                                onClick={this.managePreClientProfileNav.bind(this)}>Pre Client</button> */}
                                            
                                        </h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">

                                            <div className="row">

                                                <div className="col-md-3" onClick={this.SpecialityNav.bind(this)} style={{ cursor: "pointer" }}>
                                                    <div className="tile">
                                                        <div className="tile-heading">
                                                            <h3>Speciality</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3" onClick={this.SubSpecialityNav.bind(this)} style={{ cursor: "pointer" }}>
                                                    <div className="tile">
                                                        <div className="tile-heading" >
                                                            <h3>Sub Speciality</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3" onClick={this.ProcedureTypeNav.bind(this)} style={{ cursor: "pointer" }}>
                                                    <div className="tile-completed ">
                                                        <div className="tile-heading" style={{ textAlign: 'center' }} >
                                                            <h3>Procedure Type</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3" onClick={this.ConditionTypeNav.bind(this)} style={{ cursor: "pointer" }}>
                                                    <div className="tile-completed ">
                                                        <div className="tile-heading" style={{ textAlign: 'center' }} >
                                                            <h3>Condition Type</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3" onClick={this.CountryListNav.bind(this)} style={{ cursor: "pointer" }}>
                                                    <div className="tile-completed ">
                                                        <div className="tile-heading" style={{ textAlign: 'center' }} >
                                                            <h3>Country List</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3" onClick={this.StateListNav.bind(this)} style={{ cursor: "pointer" }}>
                                                    <div className="tile-completed ">
                                                        <div className="tile-heading" style={{ textAlign: 'center' }} >
                                                            <h3>State List</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3" onClick={this.GoToRating.bind(this)} style={{ cursor: "pointer" }}>
                                                    <div className="tile-completed ">
                                                        <div className="tile-heading" style={{ textAlign: 'center' }} >
                                                            <h3>Rating & Ranking</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-3 tile-button">
                                <button className="cetas-button"  onClick={this.createProjectNav.bind(this)}>
                                    + Create Project
                                </button>
                            </div> */}

                                            </div>
                                            <hr />



                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="tile">
                                                        <div className="tile-heading">
                                                            <h3>Total Clients</h3>
                                                            <div href="#" className="red">
                                                                <h2 className="bold" style={{color:'#C70039' , textDecoration:'none'}}>{this.state.projectInfo.total_client}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="tile">
                                                        <div className="tile-heading">
                                                            <h3>Total Projects</h3>
                                                            <div href="#" className="red">
                                                                <h2 className="bold" style={{color:'#C70039' , textDecoration:'none'}}>{this.state.projectInfo.total_projects}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="tile-completed ">
                                                        <div className="tile-heading">
                                                            <h3>Completed Projects</h3>
                                                            <div href="#" className="red">
                                                                <h2 className="bold" style={{ textAlign: 'center',color:'#C70039' , textDecoration:'none' }}>{this.state.projectInfo.completed_projects}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="tile">
                                                        <div className="tile-heading">
                                                            <h3>Ongoing Projects</h3>
                                                            <div href="#" className="red">
                                                                <h2 className="bold" style={{color:'#C70039' , textDecoration:'none'}}>{this.state.projectInfo.ongoing_projects}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="tile-completed">
                                                        <div className="tile-heading">
                                                            <h3>Upcoming Projects</h3>
                                                            <div href="#" className="red">
                                                                <h2 className="bold" style={{ textAlign: 'center',color:'#C70039' , textDecoration:'none' }}>{this.state.projectInfo.upcoming_projects}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <hr />

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2>Staff Information</h2>
                                                </div>
                                            </div>

                                            <div className="row">

                                                <div className="col-md-3">
                                                    <div className="tile">
                                                        <div className="tile-heading">
                                                            <h3>Total Managers</h3>
                                                            <div href="#" className="red">
                                                                <h2 className="bold" style={{color:'#C70039' , textDecoration:'none'}}>{this.state.employeeInfo.total_project_managers}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="tile">
                                                        <div className="tile-heading">
                                                            <h3>Total Employees</h3>
                                                            <div href="#" className="red">
                                                                <h2 className="bold" style={{color:'#C70039' , textDecoration:'none'}} >{this.state.employeeInfo.total_employee}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 tile-button">
                                                    <button className="cetas-button" onClick={this.empNav.bind(this)}>
                                                        Employee Management</button>
                                                </div>

                                            </div>
                                        </div>

                                        <form>
                                            <li></li>
                                        </form>
                                    </div>
                                    <hr />
                                    {/*  */}
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h3>Project List</h3>
                                                    <div className="employee-actions">
                                                        <div className="table-infobox">
                                                            <p className="list-count" style={{ color: 'white' }}>{this.state.ProjectList.length} Project listed</p>
                                                            <div className="filter-box float-right mb-30">
                                                                <div className="box">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <div className="input-group-text">
                                                                                <img src={require("../Assets/Images/search.svg")} /></div>
                                                                        </div>
                                                                        <input type="text" className="form-control"
                                                                            id="inlineFormInputGroupUsername"
                                                                            placeholder="Search by Project Name"
                                                                            onChange={(e) => { this.onProjectNameChange(e) }} />
                                                                    </div>
                                                                    {/* <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                      <img src={require("../Assets/Images/search.svg")} />
                                                    </div>
                                                </div>
                                                    <select className="form-control no-border-top no-border-left no-border-right" id="exampleFormControlSelect1"
                                                      onChange={ (e) => { this.onProductilter(e)}}
                                                    >
                                                              <option value="">Sort by alphabates</option>
                                                              <option>A</option>
                                                              <option>B</option>
                                                              <option>C</option>
                                                              <option>D</option>                                                        
                                                    </select>
                                                </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sort-roll">
                                                            <select className="form-control" id="exampleFormControlSelect1"
                                                                onChange={(e) => { this.onStatusFilter(e) }}
                                                            >
                                                                <option value="">Select Status</option>
                                                                <option value="completed">Completed</option>
                                                                <option value="ongoing">Ongoing</option>
                                                                <option value="upcoming">Upcoming</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <BootstrapTable keyField='id'
                                                        data={this.state.ProjectList}
                                                        columns={this.state.columns}
                                                        noDataIndication="Table is Empty"
                                                        filter={filterFactory()}
                                                        // selectRow={ selectRow }
                                                        pagination={paginationFactory(options)}
                                                        striped
                                                        hover
                                                        condensed
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <form>
                                            <li></li>
                                        </form>
                                        <div style={{ marginTop: '10%' }}></div>
                                    </div>

                                    {/*  */}
                                    <Modal isOpen={this.state.ViewProjectDetailsModal} toggle={this.toggleViewProjectDetailsModal.bind(this)}>
                                        <ModalHeader toggle={this.toggleViewProjectDetailsModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Project Details</h3>
                                        </ModalHeader>
                                        <ModalBody>
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Project Name:</span> <br />
                                                    {this.state.ProjectName}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Company Name:</span> <br />
                                                    {this.state.CompanyName}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Client Name:</span> <br />
                                                    {this.state.ClientName}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Required KOL Profile: </span> <br />
                                                    {this.state.RequiredKOLProfile}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Project Scope: </span> <br />
                                                    {this.state.ProjectScope}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Market: </span> <br />
                                                    {this.state.Market}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Start Date: </span> <br />
                                                    {this.state.StartDate}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">End Date: </span> <br />
                                                    {this.state.EndDate}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Project Plan: </span> <br />
                                                    {
                                                        (this.state.ProjectPlan == 'NULL') ? (
                                                            <text>File Not Uploaded</text>
                                                        ) :
                                                            (
                                                                <a
                                                                    href={this.state.ProjectPlan} target="_blank">
                                                                    <text>{this.state.ProjectPlan}</text>
                                                                </a>
                                                            )
                                                    }

                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Project Proposal: </span> <br />
                                                    {
                                                        (this.state.ProjectPraposal == 'NULL') ? (
                                                            <text>File Not Uploaded</text>
                                                        ) :
                                                            (
                                                                <a
                                                                    href={this.state.ProjectPraposal} target="_blank">
                                                                    <text>{this.state.ProjectPraposal}</text>
                                                                </a>
                                                            )
                                                    }

                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Project Sow: </span> <br />
                                                    {/* {this.state.ProjectSow} */}
                                                    {
                                                        (this.state.ProjectSow == 'NULL') ? (
                                                            <text>File Not Uploaded</text>
                                                        ) :
                                                            (
                                                                <a
                                                                    href={this.state.ProjectSow} target="_blank">
                                                                    <text>{this.state.ProjectSow}</text>
                                                                </a>
                                                            )
                                                    }
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Event Name: </span> <br />
                                                    {this.state.EventName}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Engagement Type: </span> <br />
                                                    {this.state.EngagementType}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Speciality:</span> <br />
                                                    {
                                                        (this.state.Speciality == '0') ? (
                                                            // <text>Speciality Not Selected</text>
                                                            <div></div>
                                                        ) : (
                                                            <div>{this.state.Speciality}</div>
                                                        )
                                                    }

                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Sub Speciality: </span> <br />
                                                    {
                                                        (this.state.SubSpecialityType == '0') ? (
                                                            <div></div>
                                                        ) : (
                                                            <div>{this.state.SubSpecialityType}</div>
                                                        )
                                                    }
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Procedure Type: </span> <br />
                                                    {
                                                        (this.state.ProcedureType == '0') ? (
                                                            <div></div>
                                                        ) : (
                                                            <div>{this.state.ProcedureType}</div>
                                                        )
                                                    }
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">No Of Participants:</span> <br />
                                                    {
                                                        (this.state.NoOfParticipants == '0') ? (
                                                            <div></div>
                                                        ) : (
                                                            <div>{this.state.NoOfParticipants}</div>
                                                        )
                                                    }
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Project Manager:</span> <br />
                                                    {this.state.ProjectManager}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Team Members:</span> <br />
                                                    {this.state.TeamMembers}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Submited By:</span> <br />
                                                    {this.state.SubmitedBy}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Shortlisted Respondents:</span> <br />
                                                    <a
                                                        href={this.state.ShortlistedRespondents} target="_blank">
                                                        <text>{this.state.ShortlistedRespondents}</text>
                                                    </a>
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Finalist Respondents:</span> <br />
                                                    <a
                                                        href={this.state.FinalistdRespondents} target="_blank">
                                                        <text>{this.state.FinalistdRespondents}</text>
                                                    </a>
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Updated ProjectStatus:</span> <br />
                                                    {this.state.UpdatedProjectStatus}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Comments:</span> <br />
                                                    {this.state.Comments}
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="pro-description">
                                                <p>
                                                    <span class="bold">Project Documents:</span> <br />
                                                    <a
                                                        href={this.state.ProjectDocuments} target="_blank">
                                                        <text>{this.state.ProjectDocuments}</text>
                                                    </a>
                                                </p>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                                onClick={this.toggleViewProjectDetailsModal.bind(this)}>Cancel</button>
                                        </ModalFooter>
                                    </Modal>

                                    {/* create credentials modal */}
                                    {/* create credentials modal */}
                                    <Modal isOpen={this.state.CraeteCrendialsModal} toggle={this.toggleCraeteCrendialsModal.bind(this)}
                                        centered >
                                        <ModalHeader toggle={this.toggleCraeteCrendialsModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Create Credentials</h3>
                                        </ModalHeader>

                                        <ModalBody>
                                            <div>
                                                <Tabs defaultActiveKey="clientCredentials">
                                                    <Tab eventKey="clientCredentials" title="Client Credentials">
                                                        <div style={{ marginTop: '5%' }}>

                                                            <FormGroup>
                                                                <div className="center">
                                                                    {/* <Input type="select" id="username"
                                        style={{width: '60%', height: '50px',}}
                                        name="KOLType"
                                        value={this.state.CreateClientCredentials.login_id} 
                                        onChange={async(e) => {
                                        let { CreateClientCredentials } = this.state;
                                        CreateClientCredentials.login_id = e.target.value;
                                        await this.setState({ CreateClientCredentials });
                                        }}  
                                    >   
                                        <option value = "0" >--Select Client Name--</option>
                                        {OptionsClientCredList}
                                    </Input> */}
                                                                    <Select
                                                                        // className="basic-single"
                                                                        styles={customStyles}
                                                                        value={this.state.selectedOptionClientCredentials}
                                                                        onChange={this.handleChangeClintName}
                                                                        options={this.state.ClientCredList.map((client, index) => {
                                                                            return {
                                                                                label: client.client_name,
                                                                                value: client.client_id,
                                                                                key: index
                                                                            };
                                                                        })}
                                                                    />
                                                                </div>
                                                                <div className="centerErrLogin" style={{ marginTop: '2%' }}>{this.state.clientNameErr}</div>
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <div className="center">
                                                                    <Input id="password" placeholder="Password"
                                                                        style={{ width: '60%', height: '50px', }}
                                                                        type="text"
                                                                        value={this.state.CreateClientCredentials.password}
                                                                        onChange={(e) => {
                                                                            let { CreateClientCredentials } = this.state;
                                                                            CreateClientCredentials.password = e.target.value;
                                                                            this.setState({ CreateClientCredentials });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            <div className="centerErrLoginPass">{this.state.PasswordErr}</div>

                                                            <div style={{ marginLeft: '40%', marginTop: '10%' }}>
                                                                <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                                                    onClick={this.toggleCraeteCrendialsModal.bind(this)}>Cancel</button>
                                                                &nbsp;&nbsp;
                                                                <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                                                    onClick={this.CreateClientCrendialsFun.bind(this)}>
                                                                    Create
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="kolCredentials" title="KOL Credentials">
                                                        <div style={{ marginTop: '5%' }}>
                                                            <FormGroup>
                                                                <div className="center">
                                                                    {/* <Input type="select" id="username"
                                        style={{width: '60%', height: '50px',}}
                                        name="KOLType"
                                        // value={this.state.CreateKOLCredentials.login_id} 
                                        onChange={async(e) => {
                                        let { CreateKOLCredentials } = this.state;
                                        CreateKOLCredentials.login_id = e.target.value;
                                        await this.setState({ CreateKOLCredentials });
                                        }}  
                                    >   
                                        <option value = "0" >--Select KOL Name--</option>
                                        {OptionsKOLCredList}
                                    </Input> */}
                                                                    <Select
                                                                        // className="basic-single"
                                                                        styles={customStyles}
                                                                        value={this.state.selectedOptionKolCredentials}
                                                                        onChange={this.handleChangeKolName}
                                                                        options={this.state.KOLCredList.map((kol, index) => {
                                                                            return {
                                                                                label: kol.name,
                                                                                value: kol.kol_unique_id,
                                                                                key: index
                                                                            };
                                                                        })}
                                                                    />
                                                                </div>
                                                                <div className="centerErrLogin" style={{ marginTop: '2%' }}>{this.state.KOLclientNameErr}</div>
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <div className="center">
                                                                    <Input id="password" placeholder="Password"
                                                                        style={{ width: '60%', height: '50px', }}
                                                                        type="text"
                                                                        value={this.state.CreateKOLCredentials.password}
                                                                        onChange={(e) => {
                                                                            let { CreateKOLCredentials } = this.state;
                                                                            CreateKOLCredentials.password = e.target.value;
                                                                            this.setState({ CreateKOLCredentials });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            <div className="centerErrLoginPass">{this.state.KOLPasswordErr}</div>
                                                            <div style={{ marginLeft: '40%', marginTop: '10%' }}>
                                                                <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                                                    onClick={this.toggleCraeteCrendialsModal.bind(this)}>Cancel</button>
                                                                &nbsp;&nbsp;
                                                                <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                                                    onClick={this.CreateKolCrendialsFun.bind(this)}>
                                                                    Create
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </ModalBody>
                                        {/* <ModalFooter style={{borderTop: '0px'}}>
                        <button class="cetas-secondry-button cetas-button-small" style={{height:'40px', width:'80px'}}
                                onClick={this.toggleCraeteCrendialsModal.bind(this)}>Cancel</button>
                        <button type="submit" class="cetas-button" style={{marginRight:'20%',}}
                            onClick={this.CreateClientCrendialsFun.bind(this)}>
                            Create
                        </button>
                </ModalFooter> */}

                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        );
    }
}

export default AdminDashboard;